import * as React from 'react';
import gear from "../../../../images/gear.png";
import s from './Uploading.module.scss';

class Uploading extends React.Component {
    get statusText() {
        const {uploadingFile, data} = this.props;
        if (data) {
            if (data.status === 'uploading') {
                if (data.filesInLineCount) {
                    return `Waiting in queue for ${data.filesInLineCount} other files...`
                }

                if (data.progress === 1) {
                    return 'Saving...'
                }

                return 'Processing...';
            }

            if (data.status === 'verification') {
                return 'Verification...'
            }
        }

        return uploadingFile ? 'File upload is complete. Preparation is in progress.' : 'Uploading...';
    }

    get progressPercent() {
        const {data, progress} = this.props;

        let result = progress;
        if (data) {
            result = data.progress;
        }

        if (!result) {
            return null;
        }

        return `${Math.round(result * 100)}%`;
    }

    render() {
        const {isUploading, addNotice, fileName, fileSize, fileDownloadStart, secondsRemaining} = this.props;
        if (!isUploading) {
            return null;
        }

        return <div className={s.container}>
            <div className={s.background} style={{
                width: this.progressPercent
            }} />
            <div className={s.upload}>
                <p className={s.progress}>{this.progressPercent}</p>
                <h3 className={s.title}>{this.statusText}</h3>
                <p className={s.notice}>{addNotice}</p>
                <img src={gear} alt="#"/>
                <p className={s.name}>{fileName}</p>
                <p className={s.size}>{fileSize}</p>
                <p className={s.start}>Upload start: {fileDownloadStart}</p>
                {!secondsRemaining || <p>{secondsRemaining} seconds remaining</p>}
            </div>
        </div>
    }
}

export default Uploading;
