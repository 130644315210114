import * as React from 'react';
import {Component} from 'react';
import SwypeID from './SwypeID';
import SwypeIDDev from './SwypeID-dev';
import Clapperboard from './Clapperboard';
import ClapperboardDev from './Clapperboard-dev';
import ClapperboardAFP from './Clapperboard-afp';
import AntiFake from './Antifake';
import ResetPassword from './ResetPassword';
import ResetPasswordConfirm from './ResetPasswordConfirm';
import { AppContext } from '../context';

import '../styles/switch-component.css';

import {Link, Switch, Route, Redirect, withRouter} from 'react-router-dom';
import Drop from "./Drop/Drop";

class SwitchComponent extends Component {

    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.onSelectedItemChanged = this.onSelectedItemChanged.bind(this);
        this.initSelectPath = this.initSelectPath.bind(this);
        this.state = {
            activeItemId: '',
            classNameSwitch: 'hidden'
        };
    };

    onSelectedItemChanged(id) {
        const { history } = this.props;
        if (this.context.verificationProcess) {
            const result = window.confirm('The check will be aborted. Do you want to switch?');
            if (!result) return;
            this.context.setVerificationProcess(false);
        }
        let classNameSwitch = '';
        this.setState({activeItemId: id});
        this.props.items.forEach((item) => {
            if (item.id === id) {
                classNameSwitch = item.className;
                if (history) history.push(item.url);
            }
        });
        this.setState({classNameSwitch: classNameSwitch});
    };

    initSelectPath(path) {
        let id = '';
        this.props.items.forEach((item) => {
            if (item.url === path) {
                id = item.id;
            }
        });
        if (this.state.activeItemId !== id)
            this.onSelectedItemChanged(id);
    };

    render() {
        return (
            <div className="wrapper">
                <div className={this.state.classNameSwitch}>
                    {this.props.items.map((item, index) =>
                        <SwitchItem
                            key={index}
                            show={item.show}
                            item={item}
                            onSelectedItemChanged={this.onSelectedItemChanged}
                            selected={item.id === this.state.activeItemId}
                        >{item.label}</SwitchItem>)}
                    <span className="slider round"/>
                </div>
                <Switch>
                    <Route exact path='/swype-id' render={(props) => (
                        <SwypeID activeItem={this.state.activeItemId} onSelectedItemChanged={this.onSelectedItemChanged} initSelectPath={this.initSelectPath} location={props.location}/>)}/>
                    <Route path='/swype-id-dev' render={(props) => (
                        <SwypeIDDev activeItem={this.state.activeItemId} onSelectedItemChanged={this.onSelectedItemChanged} initSelectPath={this.initSelectPath} location={props.location}/>)}/>
                    <Route path='/clapperboard' render={(props) => (
                        <Clapperboard activeItem={this.state.activeItemId} onSelectedItemChanged={this.onSelectedItemChanged} initSelectPath={this.initSelectPath} location={props.location}/>)}/>
                    <Route path='/clapperboard-dev' render={(props) => (
                        <ClapperboardDev activeItem={this.state.activeItemId} onSelectedItemChanged={this.onSelectedItemChanged} initSelectPath={this.initSelectPath} location={props.location}/>)}/>
                    <Route path='/fake-detector' render={(props) => (
                        <AntiFake activeItem={this.state.activeItemId} onSelectedItemChanged={this.onSelectedItemChanged} initSelectPath={this.initSelectPath} location={props.location}/>)}/>
                    <Route path='/drop' render={(props) => (
                        <Drop activeItem={this.state.activeItemId} onSelectedItemChanged={this.onSelectedItemChanged} initSelectPath={this.initSelectPath} location={props.location}/>)}
                    />
                    <Route path='/reset-password' render={(props) => (
                        <ResetPassword location={props.location}/>)}/>
                    <Route path='/reset-password-confirm' render={(props) => (
                        <ResetPasswordConfirm location={props.location}/>)}/>
                    <Route path='/clapperboard-afp' component={ClapperboardAFP}/>
                    <Redirect from='/*' to="/drop"/>
                </Switch>
            </div>
        );
    }
}

const SwitchItem = ({item, onSelectedItemChanged, selected, show}) => {
    const onMouseDownHandler = () => {
        onSelectedItemChanged(item.id);
    };
    let className = selected ? 'active' : '';
    let link = show ? (<Link className={className} onClick={onMouseDownHandler} to='#'>{item.label}</Link>) : ('');
    return link;
};

export default withRouter(SwitchComponent);
