import * as React from 'react';
import {Component} from 'react';
import ReactGA from 'react-ga';
import { YMInitializer } from 'react-yandex-metrika';
import DownloadFile from './DownloadFile';
import Menu from './Menu';
import SectionMainAntifake from './Section-main-antifake';
import SectionWorks from './Section-works';
import Footer from './Footer';

import logo from '../images/logo_bw.svg';
import af_1 from '../images/1_af.png';
import af_2 from '../images/2_af.png';
import af_3 from '../images/3_af.png';
import MobileMenuButton from "./MobileMenuButton/MobileMenuButton";
import ym from "react-yandex-metrika";

class AntiFake extends Component {
    constructor(props) {
        super(props);
        const menuData = [
            {
                img: false,
                label: "Description",
                link: "#main",
            }
        ];
        const worksData = [
            [{
                text: "The service is designed for checking video files in any formats.",
                imgLink: af_1
            },
                {
                    text: "At the core of the service, there are unique PROVER algorithms of the video analysis.",
                    imgLink: af_2
                }],
            [{
                text: "The service allows checking videos for detection of editing.",
                imgLink: af_3
            }]
        ];
        this.state = {
            menuData: menuData,
            worksData: worksData,
            titleWorks: "Features"
        };
        if (!this.props.activeItem)
            this.props.initSelectPath(this.props.location.pathname);
    }

    componentDidMount() {
        ReactGA.pageview('/fake-detector');
        ym('hit', '/fake-detector');
    }

    render() {
        return (
            <div id="prover">
                <YMInitializer accounts={[51641141]} options={{webvisor: true}} />
                <Menu menuData={this.state.menuData} logo={logo}/>
                <SectionMainAntifake/>
                <SectionWorks worksData={this.state.worksData} titleWorks={this.state.titleWorks}/>
                <DownloadFile dataForm="Antifake"/>
                <Footer menuData={this.state.menuData}/>
                <MobileMenuButton />
            </div>
        );
    }
}

export default AntiFake;
