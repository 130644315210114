import $ from "jquery";

const buttonMenuClick = (e) => {
    e.preventDefault();
    $('.mobile-menu').toggleClass("open");
    $('.back-menu-mobile').toggleClass("open");
    $('.menu > .logo').toggleClass("hidden");
};

export { buttonMenuClick };
