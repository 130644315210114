import * as React from 'react';
import {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

import '../styles/menu.css';

import $ from 'jquery';
import {buttonMenuClick} from "../utils/domHelpers";

class Menu extends Component {
    constructor(props) {
        super(props);
    };

    initScrollMenu(){
        $(document).ready(function() {
            const menuItem = $('.menu-list a, .menu-list-mobile a, .check-link, .link-for-page');
            menuItem.click(function(e) {
                if(!$(this).hasClass('market-link')) {
                    const itemClick = $(this).attr("href");
                    const destination = $(itemClick).offset().top - 50;
                    $('html, body').animate({
                        scrollTop: destination
                    }, 750);
                    e.preventDefault();
                }
            });
        });
    }

    componentDidMount() {
        this.scrollTo(this.props.location.hash);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.hash !== prevProps.location.hash) {
            this.scrollTo(this.props.location.hash);
        }
    }

    scrollTo = (id) => {
        const element = $(id);
        if (!element.length) {
            return;
        }
        this.props.history.replace(this.props.location.pathname);
        setTimeout(() => $('html, body').animate({
            scrollTop: element.offset().top - 50
        }, 750), 250);
    };

    isDrop = () => this.props.location.pathname.indexOf('/drop') === 0;
    isFile = () => !!this.props.match.params.fileId;

    getMobileButton = () => {
        if (this.isDrop) {
            if (this.isFile()) {
                return <Link to="/drop#upload-file">Upload file</Link>;
            }
            return <a onClick={buttonMenuClick} href="#upload-file">Upload file</a>;
        }

        return <a onClick={buttonMenuClick} href="#upload-file">Check file</a>;
    };

    getButton = () => {
        if (this.isDrop) {
            if (this.isFile()) {
                return <Link className="check-link" to="/drop#upload-file">Upload file</Link>;
            }
            return <a className="check-link" href="#upload-file">Upload file</a>;
        }

        return <a className="check-link" href="#upload-file">Check file</a>;
    };

    render() {
        this.initScrollMenu();
        return (
            <div className="menu">
                <a className="logo" href="https://prover.io"><img src={this.props.logo} className="logo__img" alt="Prover logo"/></a>
                <ul className="menu-list">
                    {this.props.menuData.map((menuItem, index) => {
                        return (<li className="menu-list__item" key={index}><a href={menuItem.link}>{menuItem.label}</a></li>);
                    })}
                </ul>
                {this.getButton()}
                <div className="menu-background"></div>
                <a href="/" id="trigger" className="menu-slide_button" onClick={buttonMenuClick}>
                    <span></span>
                </a>
                <div className="mobile-menu">
                    <a className="logo" href="https://prover.io"><img src={this.props.logo} className="logo__img" alt="Prover logo"/></a>
                    <ul className="menu-list-mobile">
                        {this.props.menuData.map((menuItem, index) => {
                            return (<li className="menu-list__item" key={index}><a onClick={buttonMenuClick} href={menuItem.link}>{menuItem.label}</a></li>);
                        })}
                        <li className="menu-list__item" style={{textAlign: 'left'}}>{this.getMobileButton()}</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default withRouter(Menu);
