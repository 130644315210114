import * as React from 'react';
import {Component} from 'react';

import '../styles/footer.css';

import facebook from '../images/facebook.png';
import twitter from '../images/twitter.png';
import telegram from '../images/telegram.png';
import reddit from '../images/reddit.png';
import medium from '../images/medium.png';
import youtube from '../images/youtube.png';
import logo from '../images/logo_bw.svg';
import {withRouter, Link} from "react-router-dom";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {currentYear: new Date().getFullYear()};
    }

    isDrop = () => this.props.location.pathname.indexOf('/drop') === 0;
    isFile = () => !!this.props.match.params.fileId;

    getButton = () => {
        if (this.isDrop) {
            if (this.isFile()) {
                return <Link className="link-for-page" to="/drop#upload-file">Upload file</Link>;
            }
            return <a className="link-for-page" href="#upload-file">Upload file</a>;
        }

        return <a className="link-for-page" href="#upload-file">Check file</a>;
    };

    render() {
        return (
            <footer>
                <div className="container">
                    <h3>Join our community</h3>
                    <div className="block-social">
                        <div className="row">
                            <a target="_blank" rel="noopener noreferrer" className="social_link" href="https://www.facebook.com/prover.blockchain/"><img src={facebook} alt=""/></a>
                            <a target="_blank" rel="noopener noreferrer" className="social_link" href="https://twitter.com/prover_io"><img src={twitter} alt=""/></a>
                            <a target="_blank" rel="noopener noreferrer" className="social_link" href="https://www.youtube.com/channel/UC4HrOTbaDv1fNKwSHIY1ixw"><img src={youtube} alt=""/></a>
                        </div>
                        <div className="row">
                            <a target="_blank" rel="noopener noreferrer" className="social_link" href="https://t.me/joinchat/AAHHrURp4xhK-RuCYhtPlA"><img src={telegram} alt=""/></a>
                            <a target="_blank" rel="noopener noreferrer" className="social_link" href="https://medium.com/prover"><img src={medium} alt=""/></a>
                            <a target="_blank" rel="noopener noreferrer" className="social_link" href="https://www.reddit.com/r/Prover/"><img src={reddit} alt=""/></a>
                        </div>
                    </div>
                    <hr/>
                    <div className="block-information">
                        <div className="logo">
                            <img src={logo} alt=""/>
                        </div>
                        <div className="prover_link">
                            <h5>prover.io</h5>
                            <ul>
                                <li><a href="https://prover.io/#whatisprover" target="_blank" rel="noopener noreferrer">Description</a></li>
                                <li><a href="https://prover.io/#howitworks-1" target="_blank" rel="noopener noreferrer">How it works</a></li>
                                <li><a href="https://prover.io/#typework" target="_blank" rel="noopener noreferrer">Use cases</a></li>
                                <li><a href="https://prover.io/#roadmap" target="_blank" rel="noopener noreferrer">Road map</a></li>
                            </ul>
                        </div>
                        <div className="product_link">
                            <h5>product.prover.io</h5>
                            <ul>
                                <li><a href="/">Prover SWYPE ID</a></li>
                                <li><a href="/clapperboard">Prover Clapperboard</a></li>
                                <li><a href="/fake-detector">Prover Fake detector</a></li>
                                <li><a href="/drop">Prover Drop</a></li>
                            </ul>
                            <ul>
                                {this.props.menuData.map((menuItem, index) => {
                                    return (<li className="link-for-page" key={index}><a href={menuItem.link}>{menuItem.label}</a></li>);
                                })}
                                <li>{this.getButton()}</li>
                            </ul>
                        </div>
                        <div className="subscription">

                        </div>
                    </div>
                    <div className="footnote">
                        <div className="contacts">
                            <p>Privacy policy</p><br/>
                            <p>Contacts</p><a href="mailto:info@prover.io">info@prover.io</a>
                        </div>
                        <div className="copyright">Prover © {this.state.currentYear}</div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default withRouter(Footer);
