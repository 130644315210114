const production = 
    window.location.host.indexOf('product-test') < 0 &&
    window.location.host.indexOf('localhost') < 0;

export const config = {
    apiUrl: production ? 'https://api.prover.io' : 'https://test-api.prover.io',
    frontUrl: production ? 'https://product.prover.io' : 'https://product-test.prover.io',
    checkVideoByUrl: true,
    captchaKey: '6LdQNYoUAAAAAAZupLUqoNZUjkHHupvCdZqxLgd3',
};
