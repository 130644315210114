import * as React from 'react';
import SectionInstruction from "../../Section-instruction";

class Instruction extends React.Component {
    render() {
        return <SectionInstruction
            titleInstruction="PROVER DROP – simple, quick and convenient way to show POL/POF files and other sensitive info without sharing it"
            instructionData={[
                {
                    text: "Upload your video, image or PDF file to PROVER Drop",
                    videoLink: ""
                },
                {
                    text: "Wait until the file is verified and an indelible watermark is applied to it",
                    videoLink: ""
                },
                {
                    text: "Get the link to download of this file and share it with your counterparty",
                    videoLink: ""
                },
                {
                    text: "Now you can be sure it cannot be reused in other deals because of watermark",
                    videoLink: ""
                },
                {
                    text: "Uploaded file will be deleted automatically in 72 hours",
                    videoLink: ""
                },
                {
                    text: "Use PROVER Drop only for sharing videos, images and PDFs in all your deals",
                    videoLink: ""
                }
            ]}
        />;
    }
}

export default Instruction;
