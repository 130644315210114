import * as React from 'react';
import {Component} from 'react';

import '../styles/section-try-it.css';

import icon_apple from '../images/icon_apple.png';
import icon_android from '../images/icon_android.png';

class SectionTryIt extends Component {
    render() {
        let arrayMarket = this.props.linkMarket;
        return (
            <section id="try-it" className="try-it">
                <div className="container">
                    <h3>{this.props.titleTryIt}</h3>
                </div>
                <div className="block_market">
                    <hr/>
                    <div className="container">
                        {Object.keys(this.props.linkMarket).map((item, index) =>
                            <MarketItem
                                key={index}
                                item={arrayMarket[item]}
                                icon={item} />
                        )}
                    </div>
                    <hr/>
                </div>
                <div className="container">

                </div>
            </section>
        );
    }
}

const MarketItem = ({item, icon}) => {
    let marketBlock = item.link ? (
        <div className="block-link_market">
            <a href={item.link}><img className="icon_link_market" src={icon === 'apple' ? icon_apple: icon_android} alt="icon link market"/><span>{item.description}</span></a>
        </div>) : ('');
    return marketBlock;
};

export default SectionTryIt;