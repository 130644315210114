import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';

import s from './ViewPdf.module.scss';
import $ from "jquery";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ViewPdf({src}) {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
        $(document).on('contextmenu', 'canvas', function() {
            return false;
        })
    }

    return (
        <div className={s.container}>
            <div className={s.content}>
                <Document
                    file={src}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {
                        Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    className={s.page}
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    width={window.innerWidth * 0.8}
                                />
                            ),
                        )
                    }
                </Document>
            </div>
        </div>
    );
}
