import React, {Component} from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

class CheckVideoByUrl extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <div className={classNames({
                    'check-video-by-url': this.props.isVideoByUrl,
                    'hidden': !this.props.isVideoByUrl,
                })}>
                <input type="text" ref="inputUrl" id="input-url-video" onChange={this.props.handleChangeUrlVideo} value={this.props.urlVideo} placeholder="Paste the video URL YouTube or Vimeo"/>
                <div className="check-btn" onClick={() => this.props.videoDownloaderStart()}>Check</div>
                <div className={classNames({
                        'hidden': !this.props.messageVideoByUrl,
                        'message-video-by-url': true
                        })}>{this.props.messageVideoByUrl}</div>
            </div>
        );
    }
}

export default CheckVideoByUrl;
