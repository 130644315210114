import * as React from 'react';
import {Component} from 'react';

import '../styles/section-main.css';

import googleplay_white from '../images/googleplay_white.svg';
import appstore_white from '../images/appstore_white.svg';
import phone_swype_id_back from '../images/phone_swype_id_back.png';
import phone_clapperboard_front from '../images/phone_clapperboard_front.png';

class SectionMainClapperboard extends Component {
    render() {
        return (
            <section id="main" className="main">
                <div className="container">
                    <hr className="right"/>
                    <div className="description">
                        <div className="description-wrapper">
                            <div className="block-description">
                                <h4>The unique application for digital signing of videos taken on cameras that do not have Internet connection.</h4>
                            </div>
                            <div className="block-description">
                                <p>Install the application:</p>
                                <div className="block-market_link">
                                    <a href={this.props.linkMarket.apple.link} className="market_link" target="_blank" rel="noopener noreferrer"><img src={appstore_white} alt=""/></a>
                                    <a href={this.props.linkMarket.android.link} className="market_link" target="_blank" rel="noopener noreferrer"><img src={googleplay_white} alt=""/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-phone">
                        <img className="img-back" src={phone_swype_id_back} alt=""/>
                        <img className="img-front" src={phone_clapperboard_front} alt=""/>
                    </div>
                    <hr className="left"/>
                </div>
            </section>
        );
    }
}

export default SectionMainClapperboard;