import * as React from 'react';
import {Component} from 'react';

import '../styles/section-main.css';

import antifake from '../images/antifake.png';

class SectionMainAntifake extends Component {
    render() {
        return (
            <section id="main" className="main antifake">
                <div className="container">
                    <div className="description">
                        <div className="description-wrapper">
                            <div className="block-description">
                                <h4>Universal video verification service for checking the traces of montage and splicing.</h4>
                            </div>
                        </div>
                    </div>
                    <div className="main-phone">
                        <img className="img-front" src={antifake} alt=""/>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionMainAntifake;