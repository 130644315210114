import * as React from 'react';
import {Component} from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import Menu from '../Menu';
import Footer from '../Footer';

import logo from '../../images/logo_bw.svg';
import MainSectionDrop from "./MainSection/MainSectionDrop";
import UploadFile from "./UploadFile/UploadFile";
import Instruction from "./Instraction/Instruction";
import MobileMenuButton from "../MobileMenuButton/MobileMenuButton";
import ym from "react-yandex-metrika";

class Drop extends Component {
    constructor(props) {
        super(props);
        if (!this.props.activeItem)
            this.props.initSelectPath(this.props.location.pathname);
    }

    componentDidMount() {
        ReactGA.pageview('/drop');
        ym('hit', '/drop');
        ReactPixel.pageView();
    }

    menuData = [{
        img: false,
        label: "Description",
        link: "#main",
    }];

    render() {
        return (
            <div id="prover">
                <Menu menuData={this.menuData} logo={logo}/>
                <MainSectionDrop/>
                <Instruction />
                <UploadFile/>
                <Footer menuData={this.menuData}/>
                <MobileMenuButton />
            </div>
        );
    }
}

export default Drop;
