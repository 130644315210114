import CryptoJS, {SHA256} from'crypto-js';
import base64 from 'base-64';

import {config} from '../config.js';

const URL_API = config.apiUrl;

const hc_HexInBin = x => {
    let ret = '';
    switch(x.toUpperCase()) {
        case '0': ret = '0000'; break; case '1': ret = '0001'; break;
        case '2': ret = '0010'; break; case '3': ret = '0011'; break;
        case '4': ret = '0100'; break; case '5': ret = '0101'; break;
        case '6': ret = '0110'; break; case '7': ret = '0111'; break;
        case '8': ret = '1000'; break; case '9': ret = '1001'; break;
        case 'A': ret = '1010'; break; case 'B': ret = '1011'; break;
        case 'C': ret = '1100'; break; case 'D': ret = '1101'; break;
        case 'E': ret = '1110'; break; case 'F': ret = '1111'; break;
        default : ret = '0000';
    }
    return ret;
}

const hc_ExtractBits = (hex_string, num_bits) => {
    let bit_string = "";
    let num_chars = Math.ceil(num_bits / 4);
    for(let i = 0; i < num_chars; i++)
        bit_string = bit_string + "" + hc_HexInBin(hex_string.charAt(i));

    bit_string = bit_string.substr(0, num_bits);
    return bit_string;
}

const formatDate = date => {
    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd;
  
    let mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;
  
    let yy = date.getFullYear() % 100;
    if (yy < 10) yy = '0' + yy;
  
    return '' + yy + mm + dd;
}


const getHashCashTaskAsync = (resource, service) => {
    return new Promise((resolve, reject) => {

    const url = `${URL_API}/${service}/HashCashTask`;

    const options = {
        method: 'GET',
    }
    fetch(url, options)
        .then(response => response.json())
        .then( responseJson => {
            const date = new Date();
            const dateStr = formatDate(date);
            const result = `1:${responseJson.data.difficulty}:${dateStr}:${resource}::${responseJson.data.saltBase64}:`;

            let i = 0;
            while (1) {
                const hsa1Str = '' + CryptoJS.SHA1(result + base64.encode(i.toString(2)));
                if (hc_ExtractBits(hsa1Str, 14) == '00000000000000') {
                    // console.log(' iter --- ', i);
                    const date2 = new Date();
                    // console.log('time ---', date2 - date, ' ms');
                    resolve(result + base64.encode(i.toString(2)));
                    break
                }
                i++;
            }
        })
        .catch((err) => {
            console.log(err);
        });
    });
}


export const getHashCashTask = async (resource, service) => {
    try {
        return await getHashCashTaskAsync(resource, service);
    } catch(err) {
        console.log(err);
    }
}