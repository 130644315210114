import * as React from 'react';
import Menu from "../../Menu";
import logo from "../../../images/logo_bw.svg";
import Footer from "../../Footer";
import fetch from "isomorphic-fetch";
import ReactPlayer from 'react-player'

import s from './ViewFile.module.scss';
import ViewImage from "./ViewImage/ViewImage";
import AnalyzeResult from "../AnalyzeResult/AnalyzeResult";
import classNames from "classnames";
import $ from "jquery";
import ViewPdf from "./ViewPdf/ViewPdf";
import {config} from "../../../config";
import {dateStrFromTs} from "../../../utils/dateTimeHelper";
import {Link} from "react-router-dom";
import Instruction from "../Instraction/Instruction";
import MobileMenuButton from "../../MobileMenuButton/MobileMenuButton";

class ViewFile extends React.Component {
    state = {};

    componentDidMount() {
        fetch(`${config.apiUrl}/drop/Drop/Status?fileId=${this.props.match.params.fileId}`, {method: 'GET'})
            .then(response => response.json())
            .then(json => this.setState(json));
        $(document).on('contextmenu', 'video', function() {
            return false;
        })
    }

    fileView = (src) => {
        if (this.isPdf(src)) {
            return <ViewPdf src={src} />;
        }

        if (src.toLowerCase().indexOf('.mp4') < 0 && src.toLowerCase().indexOf('.mov') < 0 && src.toLowerCase().indexOf('.avi') < 0) {
            return <ViewImage src={src}/>;
        }

        return <ReactPlayer url={src} controls config={{ file: { attributes: { controlsList: 'nodownload' } } }} />;
    };

    isPdf = src => src.toLowerCase().indexOf('.pdf') !== -1;

    render() {
        const {data, error} = this.state;

        const isError = error || data && data.status !== 'ready';

        return <div className="wrapper">
            <div id="prover" className="container">
                <Menu menuData={[]} logo={logo}/>
                {isError ? <section className={s.container}>
                        <h2>File not found.</h2>
                        <Link to="/drop#upload-file" className={s.button}>
                            Upload new file
                        </Link>
                    </section> :
                    <section className={s.container}>
                        {data && <h4>Uploaded {dateStrFromTs(new Date(data.created).getTime() / 1000)}</h4>}
                        {data && data.downloadUrl && this.fileView(data.downloadUrl)}
                        {isError || data && data.verificationResult && data.verificationResult.proven &&
                        <div className={classNames({
                            'box': true,
                            'has-advanced-upload': true,
                            'is-result': true,
                        })}>
                            <div className="box__result">
                                <div className="result_information">
                                    <AnalyzeResult data={data.verificationResult}/>
                                </div>
                            </div>
                        </div>}
                    </section>}
                <Instruction/>
                <Link to="/drop#upload-file" className={s.button}>
                    Upload file
                </Link>
                <Footer menuData={[]}/>
                <MobileMenuButton />
            </div>
        </div>
    }
}

export default ViewFile;
