import * as React from 'react';
import {isIOS} from "react-device-detect";

class Input extends React.Component {
    showAlertForIos = () => {
        if (isIOS) {
            alert("Upload file from the 'Files' folder to avoid file corruption.");
        }
    }

    render() {
        const {finished, inputRef, handleSubmit} = this.props;
        return <div className={finished || "box__input"} hidden={finished}>
            <h3 className="box__verification">{"File upload (jpeg, png, heic, heif, pdf, mp4, avi, mov < 100 Mb)"}</h3>
            <div className="block__button">
                <input
                    ref={inputRef}
                    onChange={handleSubmit}
                    type="file"
                    name="file"
                    id="file"
                    className="box__file"
                    accept=".pdf,.mp4,.avi,.quicktime,.jpeg,.jpg,.png,.mov,.heic,.heif"
                />
                <label className="box__labelFile_default" htmlFor="file" onClick={this.showAlertForIos}>
                    <strong>Choose a file</strong>
                </label>
                <span className="box__dragndrop"> or drag it here.</span>
                <label className="box__labelFile_file" htmlFor=""/>
                <button type="submit" className="box__button">Upload</button>
                {isIOS && <p className="forIosFiles">Upload a file from the "Files" folder.</p>}
            </div>
        </div>
    }
}

export default Input;
