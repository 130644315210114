import * as React from 'react';
import {Component} from 'react';
import ReactGA from 'react-ga';
import DownloadFile from './DownloadFile';
import Menu from './Menu';
import SectionMain from './Section-main';
import SectionWorks from './Section-works';
import SectionInstruction from './Section-instruction';
import SectionTryIt from './Section-try-it';
import Footer from './Footer';

import logo from '../images/logo_bw.svg';
import pr_1 from '../images/1_pr_new.png';
import pr_2 from '../images/2_pr_new.png';
import pr_3 from '../images/3_pr_new.png';
import pr_4 from '../images/4_pr_new.png';
import MobileMenuButton from "./MobileMenuButton/MobileMenuButton";
import ym from "react-yandex-metrika";

class SwypeID extends Component {
    constructor(props) {
        super(props);
        const menuData = [
            {
                img: false,
                label: "Description",
                link: "#main",
            },
            {
                img: false,
                label: "How it works",
                link: "#instruction",
            },
            {
                img: false,
                label: "Try it now",
                link: "#try-it",
            },
        ];
        const worksData = [
            [{
                text: "The reliability of the recorded event is confirmed by the video file authenticity, the absence of splicing, montage and other modifications.",
                imgLink: pr_1
            },
                {
                    text: "Each video taken with the application corresponds to the swype code entered by a user and the video file hash stored in the blockchain.",
                    imgLink: pr_2
                }],
            [{
                text: "Authentication of the video file is carried out due to its online verification. If the hash matches the swype code the video file is considered authentic.",
                imgLink: pr_3
            },
                {
                    text: ["The application requires Internet connection. The application supports most modern Android ", <span key="0" className='footnote-star'>**</span>, " and iOS ", <span key="1" className='footnote-star'>***</span>, "- based phones and tablets."],
                    imgLink: pr_4
                }]
        ];
        const instructionData = [
            {
                text: "Install the app and sing up your account",
                videoLink: ""
            },
            {
                text: "Recharge your balance",
                videoLink: ""
            },
            {
                text: "Record video using PROVER Swype ID app by following the instructions on the screen",
                videoLink: ""
            },
            {
                text: "Check this very video with Prover online-service",
                videoLink: ""
            }
        ];
        const linkMarket = {
            apple: {link: "https://itunes.apple.com/us/app/prover-swype-id/id1420462547", description: "For IOS"},
            android: {link: "https://play.google.com/store/apps/details?id=io.prover.swypeid", description: "For Android"},
        };
        this.state = {
            menuData: menuData,
            instructionData: instructionData,
            linkMarket: linkMarket,
            worksData: worksData,
            titleWorks: "Key aspects of PROVER SWYPE ID",
            titleInstruction: "PROVER SWYPE ID – simple, quick and convenient",
            titleTryIt: "Try PROVER SWYPE ID right now"
        };
        if (!this.props.activeItem) {
            this.props.initSelectPath(this.props.location.pathname);
        }
    }

    componentDidMount() {
        ReactGA.pageview('/swype-id');
        ym('hit', '/swype-id');
    }

    render() {
        return (
            <div id="prover">
                <Menu menuData={this.state.menuData} logo={logo}/>
                <SectionMain linkMarket={this.state.linkMarket}/>
                <SectionWorks worksData={this.state.worksData} titleWorks={this.state.titleWorks}/>
                <SectionInstruction instructionData={this.state.instructionData} titleInstruction={this.state.titleInstruction}/>
                <SectionTryIt linkMarket={this.state.linkMarket} titleTryIt={this.state.titleTryIt}/>
                <DownloadFile dataForm="Prover"/>
                <Footer menuData={this.state.menuData}/>
                <MobileMenuButton />
            </div>
        );
    }
}

export default SwypeID;
