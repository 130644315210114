import CryptoJS from "crypto-js";

const getSha256Async = file => {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();

        fr.onload = () => {
            const wordArray = CryptoJS.lib.WordArray.create(fr.result);
            const fileSha256Hash = "" + CryptoJS.SHA256(wordArray);
            resolve(fileSha256Hash);
        };

        fr.onerror = reject;

        fr.readAsArrayBuffer(file);
    })
};

export { getSha256Async };
