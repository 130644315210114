import * as React from 'react';
import {buttonMenuClick} from "../../utils/domHelpers";

class MobileMenuButton extends React.Component {
    render() {
        return <div className="back-menu-mobile" onClick={buttonMenuClick} />;
    }
}

export default MobileMenuButton;
