import * as React from 'react';
import {Component} from 'react';
import SwitchComponent from './Switch-component';
import { AppContext } from '../context';

class App extends Component {
    constructor(props) {
        super(props);
        const mainMenuItems = [{
            id: 1,
            url: '/drop',
            label: 'Drop',
            className: "switch drop",
            select: false,
            show: true,
        }, {
            id: 2,
            url: '/swype-id',
            label: 'SWYPE ID',
            className: "switch prover",
            select: true,
            show: true,
        }, {
            id: 3,
            url: '/clapperboard',
            label: 'Clapperboard',
            className: "switch clapperboard",
            select: false,
            show: true,
        }, {
            id: 4,
            url: '/fake-detector',
            label: 'Fake detector',
            className: "switch antifake",
            select: false,
            show: true,
        }];
        this.state = {
            mainMenuItems: mainMenuItems,
            verificationProcess: false
        };

        this.setVerificationProcess = value => {
            this.setState({
                verificationProcess: value
            });
        };
    }

    render() {
        return (
            <AppContext.Provider value={{verificationProcess: this.state.verificationProcess, setVerificationProcess: this.setVerificationProcess}}>
                <SwitchComponent items={this.state.mainMenuItems}/>
            </AppContext.Provider>
        );
    }
}

export default App;
