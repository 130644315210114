import * as React from 'react';
import {config} from "../../../../config";
import AnalyzeResult from "../../AnalyzeResult/AnalyzeResult";

class Result extends React.Component {
    render() {
        const {finished, isError, data, textError, hrefMap, noteLocation, uploadAnother, analyzeData} = this.props;

        const fileId = data && data.fileId;

        return <div className="box__result" hidden={!finished}>
            {!isError && <h3 className="box__verification">Done!</h3>}
            {!isError && fileId && <>
                <h4>
                    <a href={`${config.frontUrl}/drop/${fileId}`}>{`${config.frontUrl}/drop/${fileId}`}</a>
                </h4>
                {navigator.share && <h4>
                    <a onClick={() => navigator.share({
                        title: 'Share Prover Drop file',
                        text: 'Prover Drop file',
                        url: `${config.frontUrl}/drop/${fileId}`,
                    })}>Share</a>
                </h4>}
            </>}
            {isError && <h3 className="box__verification error">{textError}</h3>}
            <div className="result_information">
                <AnalyzeResult data={analyzeData}/>
                <div className="buttons" onClick={uploadAnother}>
                    <strong className="upload-red-button">
                        Upload another file
                    </strong>
                </div>
                {hrefMap &&
                <div className="buttons location-button">
                    <a className="upload-red-button" target="_blank" rel="noopener noreferrer"
                       href={hrefMap}>{noteLocation}</a>
                </div>}
            </div>
        </div>
    }
}

export default Result;
