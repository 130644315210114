import * as React from 'react';
import {dateStrFromTs} from '../../../utils/dateTimeHelper';

class AnalyzeResult extends React.Component {
    render() {
        const {data} = this.props;
        let analyzeData = data;

        if (!data || !data.final) {
            return null;
        }

        let analyze_paragraphes = [];
        analyze_paragraphes.push(<p key="analyzeData-proven" className="p_info">
            Proven: <span>{analyzeData.proven ? 'Yes' : 'No'}</span>
        </p>);
        if (analyzeData.error) {
            analyze_paragraphes.push(<p key="analyzeData-error" className="p_info">
                Message: <span>{analyzeData.error}</span>
            </p>);
        }
        if (analyzeData.detail) {
            analyze_paragraphes.push(<p key="analyzeData-detail" className="p_info">
                Detail: <span>{analyzeData.detail}</span>
            </p>);
        }
        if (analyzeData.prover && analyzeData.prover.mediaHash) {
            analyze_paragraphes.push(<p key="analyzeData-mediaHash" className="p_info">
                Media hash: <span>{analyzeData.prover.mediaHash}</span>
            </p>);
        }
        if (analyzeData.prover && analyzeData.prover.submit && analyzeData.prover.submit.transactionInfo) {
            analyze_paragraphes.push(<p key="analyzeData-submitMediaHash-transactionHash" className="p_info">
                Transaction hash of media submitting:
                <span><a target="_blank"
                         href={'http://explorer.nemtool.com/?#/s_tx?hash=' + analyzeData.prover.submit.transactionInfo.transactionHash}>
                    {analyzeData.prover.submit.transactionInfo.transactionHash}
                </a></span>
            </p>);
            analyze_paragraphes.push(<p key="analyzeData-submitMediaHash-blockTimestamp" className="p_info">
                Date-time of media submitting:
                <span>{dateStrFromTs(analyzeData.prover.submit.transactionInfo.blockInfo.blockTimestamp)}</span>
            </p>);
            if (analyzeData.prover.swypeSearch && analyzeData.prover.swypeSearch.searched) {
                analyze_paragraphes.push(<p key="analyzeData-swype" className="p_info">
                    Swype code: <span>{analyzeData.prover.reference.swype}</span>
                </p>);
                if (analyzeData.prover.swypeSearch.found) {
                    analyze_paragraphes.push(<p key="analyzeData-swypeBeginEnd" className="p_info">
                        Swype begin/end:
                        <span>{analyzeData.prover.swypeSearch.swypeCodeBeginOffset}/{analyzeData.prover.swypeSearch.swypeCodeEndOffset}</span>
                    </p>);
                } else {
                    analyze_paragraphes.push(<p key="analyzeData-swypeBeginEnd" className="p_info">
                        Swype begin/end:
                        <span>not found</span>
                    </p>);
                }
                let prover_reference_blockInfo_blockTimestamp = 0;
                if (analyzeData.prover.reference.transactionInfo) {
                    analyze_paragraphes.push(<p key="analyzeData-swype-reference-transactionHash" className="p_info">
                        Transaction hash of swype:
                        <span><a target="_blank"
                                 href={'http://explorer.nemtool.com/?#/s_tx?hash=' + analyzeData.prover.reference.transactionInfo.transactionHash}>
                            {analyzeData.prover.reference.transactionInfo.transactionHash}
                        </a></span>
                    </p>);
                    prover_reference_blockInfo_blockTimestamp = analyzeData.prover.reference.transactionInfo.blockInfo.blockTimestamp;
                } else {
                    analyze_paragraphes.push(<p key="analyzeData-swype-reference-blockHeight" className="p_info">
                        Block height of swype:
                        <span><a target="_blank"
                                 href={'http://explorer.nemtool.com/?#/s_block?height=' + analyzeData.prover.reference.blockInfo.blockHeight}>
                            {analyzeData.prover.reference.blockInfo.blockHeight}
                        </a></span>
                    </p>);
                    prover_reference_blockInfo_blockTimestamp = analyzeData.prover.reference.blockInfo.blockTimestamp;
                }
                analyze_paragraphes.push(<p key="analyzeData-swypeDatetime" className="p_info">
                    Date-time of swype:
                    <span>{dateStrFromTs(prover_reference_blockInfo_blockTimestamp)}</span>
                </p>);
            } else {
                analyze_paragraphes.push(<p key="analyzeData-noswype" className="">
                    Media submitted without swype
                </p>);
            }
        }
        if (analyzeData.clapperboard && analyzeData.clapperboard.submit && analyzeData.clapperboard.submit.transactionInfo) {
            analyze_paragraphes.push(<p key="analyzeData-submitMessage-message" className="p_info">
                Submitted message:
                <span>{analyzeData.clapperboard.submit.message}</span>
            </p>);
            analyze_paragraphes.push(<p key="analyzeData-submitMessage-transactionHash" className="p_info">
                Transaction hash of message submitting:
                <span><a target="_blank"
                         href={'http://explorer.nemtool.com/?#/s_tx?hash=' + analyzeData.clapperboard.submit.transactionInfo.transactionHash}>
                            {analyzeData.clapperboard.submit.transactionInfo.transactionHash}
                </a></span>
            </p>);
            analyze_paragraphes.push(<p key="analyzeData-submitMessage-blockTimestamp" className="p_info">
                Date-time of message submitting:
                <span>{dateStrFromTs(analyzeData.clapperboard.submit.transactionInfo.blockInfo.blockTimestamp)}</span>
            </p>);
            analyze_paragraphes.push(<p key="analyzeData-submitMessage-reference-blockHeight" className="p_info">
                Block height of message reference:
                <span><a target="_blank"
                         href={'http://explorer.nemtool.com/?#/s_block?height=' + analyzeData.clapperboard.reference.blockInfo.blockHeight}>
                        {analyzeData.clapperboard.reference.blockInfo.blockHeight}
                    </a></span>
            </p>);
            analyze_paragraphes.push(<p key="analyzeData-submitMessage-reference-blockTimestamp" className="p_info">
                Date-time of message reference:
                <span>{dateStrFromTs(analyzeData.clapperboard.reference.blockInfo.blockTimestamp)}</span>
            </p>);
        }
        if (analyzeData.va && analyzeData.va.cutdetect) {
            if (analyzeData.va.cutdetect.length) {
                analyze_paragraphes.push(<p key="analyzeData-va-cutdetect" className="p_info">
                    Detected file montage (seconds of video):
                    <span>{analyzeData.va.cutdetect.join(', ')}</span>
                </p>);
            } else {
                analyze_paragraphes.push(<p key="analyzeData-va-cutdetect" className="">
                    File montage not found
                </p>);
            }
        }

        return analyze_paragraphes;
    }
}

export default AnalyzeResult;
