import * as React from 'react';
import {Component} from 'react';

import '../styles/section-instruction.css';

class SectionInstruction extends Component {
    render() {
        return (
            <section id="instruction" className="instruction">
                <div className="container">
                    <h3>{this.props.titleInstruction}</h3>
                    <ul>
                        {this.props.instructionData.map((item, index) =>
                            <li key={index}>
                                <span className="red-line"/>
                                <h1>{index+1}</h1>
                                <p>{item.text}</p>
                                {/*<a href={item.videoLink} className="btn btn-show">Video</a>*/}
                            </li>
                        )}
                    </ul>
                </div>
            </section>
        );
    }
}

export default SectionInstruction;