import * as React from 'react';
import {Component} from 'react';
import s from './MainSectionDrop.module.scss';
import '../../../styles/section-main.css';
import drop from '../../../images/drop.png';

class MainSectionDrop extends Component {
    render() {
        return (
            <section className={s.container}>
                <h4>The most trusted file hosting: send videos, images and PDFs to your counterparty and stay sure it cannot be reused in other deals.</h4>
                <img className="img-front" src={drop} alt=""/>
            </section>
        );
    }
}

export default MainSectionDrop;
