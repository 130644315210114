import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './components/App';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {unregister} from './registerServiceWorker';
import ViewFile from "./components/Drop/ViewFile/ViewFile";
import {YMInitializer} from "react-yandex-metrika";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

ReactGA.initialize('UA-104760618-2');
ReactPixel.init('226550859028980', {
    autoConfig: true,
    debug: false
});

ReactDOM.render(
    <>
        <YMInitializer accounts={[51641141]} options={{webvisor: true}} />
        <BrowserRouter>
            <Switch>
                <Route path="/drop/:fileId" component={ViewFile} />
                <Route component={App} />
            </Switch>
        </BrowserRouter>
    </>,
    document.getElementById('root')
);
unregister();
