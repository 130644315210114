import * as React from 'react';
import {Component} from 'react';

import '../styles/section-works.css';

class SectionWorks extends Component {
    render() {
        return (
            <section id="how-it-works" className="how-it-works">
                <div className="container">
                    <h3>{this.props.titleWorks}</h3>
                    {this.props.worksData.map((itemRow, indexRow) =>
                        <div className="row" key={indexRow}>
                            {itemRow.map((itemWork, index) =>
                                <div className={itemRow.length === 1 ? "info-block last" : "info-block"} key={index}>
                                    <div className="img-background">
                                        <img src={itemWork.imgLink} alt="how it work"/>
                                    </div>
                                    <div className="info"><p>{itemWork.text}</p></div>
                                </div>
                            )}
                        </div>
                    )}
                    <p className="footnote">** the Android trademark is the property of Google.</p>
                    <p className="footnote">*** the iOS trademark is the property of Apple inc.</p>
                </div>
            </section>
        );
    }
}

export default SectionWorks;
