import React, {Component} from 'react';
import classNames from 'classnames';
import fetch from 'isomorphic-fetch';
import EXIF from 'exif-js';
import gear from '../images/gear.png';
import '../styles/DownloadFile.css';
import {isIOS} from "react-device-detect";
import CheckVideoByUrl from './CheckVideoByUrl';
import {config} from '../config.js'
import { AppContext } from '../context';
import {getHashCashTask} from './getHashCashTask.js';
import {dateStrFromTs} from "../utils/dateTimeHelper";
import {getSha256Async} from "../utils/cryptoHelper";

const URL_API = config.apiUrl;

const TYPE_SWYPE = 'swype';
const TYPE_QR = 'qr';
const TYPE_ANTIFAKE = 'antifake';
const TYPE_MEDIA = 'media';
const RECAPTCHA_KEY = config.captchaKey;
const SERVICE_VERIFICATION = "verification/Verification";
const SERVICE_DOWNLOADS = "video-downloader/Downloads";

class DownloadFile extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        const dataForm = {
            'Prover': {
                secId: 'upload-file',
                formId: "prover-uploadForm",
                maxSizeFile: 100,
                fileExtensions: ['mp4', 'avi', 'quicktime', 'jpeg', 'jpg', 'png', 'mov', 'heic', 'heif'],
                fileTypes: ['video/mp4', 'video/avi', 'video/quicktime', 'image/jpeg', 'image/png'],
                textForm: "File verification (jpeg, png, heic, heif, mp4, avi, mov < 100 Mb)",
                formAction: URL_API + "/fileverify/hash",
            },
            'Clapperboard': {
                secId: 'upload-file',
                formId: "clapperboard-uploadForm",
                maxSizeFile: 100,
                fileExtensions: ['mp4', 'avi', 'quicktime', 'jpeg', 'jpg', 'png', 'mov', 'heic', 'heif'],
                fileTypes: ['video/mp4', 'video/avi', 'video/quicktime', 'image/jpeg', 'image/png'],
                textForm: "File verification (jpeg, png, heic, heif, mp4, avi, mov < 100 Mb)",
                formAction: URL_API + "/fileverify/clapperboard",
            },
            'Antifake': {
                secId: 'upload-file',
                formId: "antifake-uploadForm",
                maxSizeFile: 100,
                fileExtensions: ['mp4', 'avi', 'quicktime', 'jpeg', 'jpg', 'png', 'mov'],
                fileTypes: ['video/mp4', 'video/avi', 'video/quicktime', 'image/jpeg', 'image/png'],
                textForm: "File verification (jpeg, png, mp4, avi, mov < 100 Mb)",
                formAction: URL_API + "/fileverify/antifake",
            }
        };
        const maxSizeFile = 100;

        this.state = {
            isUploading: 0,
            uploadingFile: false,
            isError: 0,
            isSuccess: 0,
            isResult: 0,
            hiddenCaptcha: 1,
            dataForm: dataForm[this.props.dataForm],
            maxSizeFile: maxSizeFile,
            textError: '',
            secondsRemaining: '',
            recaptchaKey: false,
            formAction: '#',
            verificationId: '',
            isVideoByUrl: this.getVerificationType() === TYPE_ANTIFAKE || this.getVerificationType() === TYPE_QR,
            messageVideoByUrl: '',
            urlVideo: '',
            antifakeByUrl: false,
            finished: false,
            addNotice: '',
            hideBlockDrag: true
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickFile = this.handleClickFile.bind(this);
        this.handleSubmitVer = this.handleSubmitVer.bind(this);
        this.verifyVideoByUrl = this.verifyVideoByUrl.bind(this);
        this.handleChangeUrlVideo = this.handleChangeUrlVideo.bind(this);

        this.droppedFiles = false;
        this.analyzeSuccess = false;
        this.analyzeData = {};

        // feature detection for drag&drop upload
        var div = document.createElement('div');
        this.isAdvancedUpload = (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
    }

    componentDidMount() {
        this.form = document.querySelector('.upload-file form');
        if (this.isAdvancedUpload) this.uploadDragDrop(this);

        // testing
        // setTimeout(() => {
        //     let data = {};
        //     try {
        //         data = JSON.parse(TEST_DATA[0]);
        //     } catch (e) {
        //     }
        //     this.updateOnResponse(data);
        // }, 100);
    }

    uploadDragDrop(App) {
        const form = App.form;

        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (event) {
            form.addEventListener(event, function (e) {
                // preventing the unwanted behaviours
                e.preventDefault();
                e.stopPropagation();
            });
        });
        ['dragover', 'dragenter'].forEach(function (event) {
            form.addEventListener(event, function () {
                form.classList.add('is-dragover');
            });
        });
        ['dragleave', 'dragend', 'drop'].forEach(function (event) {
            form.addEventListener(event, function () {
                form.classList.remove('is-dragover');
            });
        });
        form.addEventListener('drop', function (e) {
            Array.prototype.forEach.call(e.dataTransfer.files, function (file) {
                App.droppedFiles = file;
            });
            App.handleSubmit();
        });
    }

    // открытие окна загрузки файла
    handleClickFile() {
        this.droppedFiles = false;
        this.setState({
            textError: ``,
            isError: false,
            finished: false
        });
        document.getElementById('file').click();
    }

    // обработка изменения input
    handleChange() {
        this.submitForm();
    }

    hexTsToDate(hexTs) {
        return new Date(parseInt(hexTs, 10) * 1000);
    }

    updateOnResponseUpload(response) {
        //todo: check response.success
        this.verificationResult();
    }

    updateOnResponse(response) {
        this.setState({hideBlockDrag: true});
        this.context.setVerificationProcess(false);
        this.form.reset();
        this.droppedFiles = false;
        let isError = 1,
            isSuccess = 0,
            isResult = 0,
            isUploading = 0;
        let analyzeData = {};
        let certificateUrl = '';
        let pdf_swypeid = '';
        let pdf_clapperboard = '';
        if (!(response instanceof Object)) {
            analyzeData = {
                'error': 'Something went wrong'
            };
        }
        let textError = '';
        if (!response.success) {
            textError = response.error;
            analyzeData = {
                'error': response.error // todo: error codes -> human text
            };
        }
        if ('data' in response) {
            analyzeData = response.data;
        }
        if ('certificateUrl' in analyzeData && analyzeData.certificateUrl) {
            certificateUrl = analyzeData.certificateUrl;
        }
    
        if ('proven' in analyzeData) {
            if (analyzeData.proven) {
                isSuccess = 1;
                isError = 0;
            } else if (analyzeData.prover && analyzeData.prover.mediaHash && analyzeData.prover.submit !== 'pending' && analyzeData.clapperboard && analyzeData.clapperboard.submit !== 'pending') {
                textError = 'File not found. Information may not be available immediately and if you are sure that the file information has been sent, please check again in a few minutes.';
            }
        }
        if ('va' in analyzeData) {
            isSuccess = 1;
            isError = 0;
        }
        this.analyzeData = analyzeData;
        this.certificateUrl = certificateUrl;
        this.analyzeSuccess = isSuccess;
        this.setState({
            isError: isError,
            isSuccess: isSuccess,
            isResult: isResult,
            isUploading: isUploading,
            textError: textError,
            isVideoByUrl: this.getVerificationType() === TYPE_ANTIFAKE || this.getVerificationType() === TYPE_QR,
            finished: true,
            hrefMap: analyzeData.geolocation ? `https://www.google.com/maps/place/${analyzeData.geolocation.latitude}N${analyzeData.geolocation.longitude}E` : this.state.hrefMap,
            noteLocation: analyzeData.geolocation ? `Location: ${analyzeData.geolocation.latitude}N ${analyzeData.geolocation.longitude}E` : this.state.noteLocation
        });
    }

    // обработка отправки формы
    handleSubmit(event) {
        if (event) {
            event.preventDefault();
        }
        const file = this.droppedFiles || this.form.querySelector('input[type=file]').files[0];
        const fileExtension = file.name.toLowerCase().replace(/^.*\./, '');
        if (!this.state.dataForm.fileTypes.includes(file.type) &&
            !this.state.dataForm.fileExtensions.includes(fileExtension)
        ) {
            this.analyzeData = null;
            this.setState({
                textError: `Unsupported file format: ${file.type}`,
                isError: true,
                finished: true
            });
            return false;
        }
        if (Number(file.size / 1048576).toFixed(1) > this.state.dataForm.maxSizeFile) {
            this.analyzeData = null;
            this.setState({
                textError: "The file size is more than 100 Mb.",
                isError: true,
                finished: true
            });
            return false;
        }

        this.showCaptcha(RECAPTCHA_KEY);
    }

    getParameter(key) {
        let p = window.location.search;
        p = p.match(new RegExp(key + '=([^&=]+)'));
        return p ? p[1] : false;
    }

    showCaptcha(captcha_key) {
        this.setState({
            hiddenCaptcha: 0,
        });
        try {
            // try reset. for first reset send exception - no client exist
            window.grecaptcha.reset();
        } catch (exception) {
            // https://developers.google.com/recaptcha/docs/display#render_param
            window.grecaptcha.render('grecaptcha-block', {
                sitekey: captcha_key,
                theme: 'dark',
                callback: recaptchaResponse => {
                    this.verificationStart(recaptchaResponse);
                }
            });
        }
    }

    getStatusVideoByUrl = (id, t = 0) => {
        getHashCashTask(id, SERVICE_DOWNLOADS)
        .then(XHashcash => {
            const interval = 3000;
            const url = `${URL_API}/video-downloader/Downloads/Status?id=${id}`;
            let options = {
                method: 'GET',
                headers: {
                    'X-Hashcash': XHashcash
                }
            };
            fetch(url, options)
                .then(response => response.json())
                .then( responseJson => {
                    if (responseJson.data) {
                        if (responseJson.data.status === 'Downloaded') {
                            this.verificationXHashcashStartVideoDownloader(responseJson.data.videoFileName);
                        } else {
                            setTimeout(() => {
                                this.getStatusVideoByUrl(id, t + interval);
                            }, interval);
                        }
                    }
                    return;
                })
                .catch((err) => {
                    console.log(err);
                    this.updateOnResponse({
                        success: false,
                        error:'An error has occurred, please try again later'
                    });
                });
        });
        return;
    }

    verifyVideoByUrl = () => {
        const now = new Date();
        this.setState({
            isError: 0,
            isSuccess: 0,
            isResult: 0,
            isUploading: 1,
            isVideoByUrl: 0,
            messageVideoByUrl: '',
            fileDownloadStart: ("0" + now.getHours()).slice(-2) + ":" + ("0" + now.getMinutes()).slice(-2) + ":" + ("0" + now.getSeconds()).slice(-2),
            uploadingFile: false,
            secondsRemaining: '',
            finished: false,
            fileName: this.state.urlVideo,
            fileSize: ''
        });
        this.simulationUpload();
    }

    // отправка формы
    handleSubmitVer() {
        const sendFileUrl = URL_API + '/verification/Verification/UploadFile';

        // preventing the duplicate submissions if the current one is in progress
        if (this.state.isUploading) {
            return false;
        }
        this.setState({
            isError: 0,
            isSuccess: 0,
            isResult: 0,
            formAction: sendFileUrl,
            uploadingFile: false,
            isVideoByUrl: 0,
            finished: false,
        });
        const form = this.form;
        let fileSend = form.querySelector('input[type=file]').files[0];
        const App = this;

        if (this.isAdvancedUpload) { // ajax file upload for modern browsers
            // gathering the form data
            let ajaxData = new FormData(form);
            ajaxData.append('VerificationSessionToken', this.state.verificationId);

            if (this.droppedFiles) {
                fileSend = this.droppedFiles;
                ajaxData.append(form.querySelector('input[type=file]').getAttribute('name'), fileSend);
            }
            // ajax request
            var ajax = new XMLHttpRequest(),
                remind,
                progress = form.querySelector('.progress'),
                uploadBackground = form.querySelector('.upload-background');
            const now = new Date();
            const timeStart = now.getTime();

            this.setState({
                isUploading: 1,
                fileName: fileSend.name,
                fileSize: Number(fileSend.size / 1048576).toFixed(1) + " Mb",
                fileDownloadStart: ("0" + now.getHours()).slice(-2) + ":" + ("0" + now.getMinutes()).slice(-2) + ":" + ("0" + now.getSeconds()).slice(-2),
            });
            const App = this;
            ajax.upload.addEventListener('progress', function (e) {
                const timeLoad = new Date().getTime() - timeStart;
                const uploadPerc = 100 * e.loaded / e.total;
                const secondsRemaining = Math.round((100 * timeLoad / uploadPerc - timeLoad) / 1000);
                App.setState({secondsRemaining: secondsRemaining});
                uploadBackground.style.width = uploadPerc + '%';
                uploadBackground.style.max = e.total + '%';
                remind = uploadPerc;
                if (remind === 100) {
                    this.setState({uploadingFile: true});
                }
                progress.innerHTML = Math.round(remind) + ' %';
            }.bind(this));
            ajax.open(form.getAttribute('method'), form.getAttribute('action'), true);

            // отобразить результат загруки файла, вызвать ver.Result
            ajax.onload = function () {
                if (ajax.status >= 200 && ajax.status < 400) {
                    try {
                        var verification_send_file_response = JSON.parse(ajax.responseText);
                        App.updateOnResponseUpload(verification_send_file_response);
                    } catch (exception) {
                        // Ошибка загрузки файла
                        App.updateOnResponseUpload({
                            success: false,
                            error: 'upload exception 😱: ' + ajax.responseText
                        });
                    }
                } else {
                    // ошибка отправки файла
                    App.updateOnResponseUpload({
                        success: false,
                        error: 'upload exception ☹️: ' + ajax.status
                    });
                }
            };

            ajax.onerror = function () {
                form.classList.remove('is-uploading');
                alert('Error. Please, try again!');
                App.setState({
                    isUploading: 0,
                })
            };
            ajax.send(ajaxData);
        } else { // fallback Ajax solution upload for older browsers
            var iframeName = 'uploadiframe' + new Date().getTime(),
                iframe = document.createElement('iframe');
            iframe.setAttribute('name', iframeName);
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            form.setAttribute('target', iframeName);
            iframe.addEventListener('load', function () {
                var response = JSON.parse(iframe.contentDocument.body.innerHTML);
                form.removeAttribute('target');
                App.updateOnResponseUpload(response);
                iframe.parentNode.removeChild(iframe);
            });
        }
    }

    // отправка формы
    submitForm() {
        var event = document.createEvent('HTMLEvents');
        event.initEvent('submit', true, true);
        this.form.dispatchEvent(event);
    }

    showSubtitle() {
        let subtitle;
        switch (this.getVerificationType()) {
            case TYPE_SWYPE:
                subtitle = 'SWYPE ID';
                break;
            case TYPE_ANTIFAKE:
                subtitle = 'Fake detector';
                break;
            default:
                subtitle = this.props.dataForm;
                break;
        }
        return subtitle;
    }

    getVerificationType = () => {
        switch (this.props.dataForm) {
            case 'Antifake':
                return TYPE_ANTIFAKE;
            case 'Clapperboard':
                return TYPE_QR;
            case 'Prover':
                return TYPE_SWYPE;
            default:
                return TYPE_MEDIA;
        }
    };

    getSha256 = async (fileSend) => {
        try {
            return await getSha256Async(fileSend);
        } catch(err) {
            console.log(err);
        }
    };

    // verification X-Hashcash start Video-downloader
    verificationXHashcashStartVideoDownloader = (fileName) => {
        getHashCashTask('', SERVICE_VERIFICATION)
        .then(XHashcash => {

        const url = URL_API + "/verification/Verification/CreateVerificationSession";
        const data = {
            fileName: fileName,
            type: this.getVerificationType() === TYPE_ANTIFAKE ? "cut-detect" : "prover",
            // fileSha256Hash: '',
        };
        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Hashcash': XHashcash
            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then( responseJson => {
                this.setState({
                    verificationId: responseJson.data.verificationSessionToken,
                }, function() {this.startVerification(fileName)});
            })
            .catch((err) => {
                console.log(err);
                this.updateOnResponse({
                    success: false,
                    error:'An error has occurred, please try again later'
                });
            });
        });
    }

    // Start verification of file downloaded with video-downloader service
    startVerification = (fileName) => {
        const url = `${URL_API}/verification/Verification/StartVerification`;
        const data = {
            downloadedFileName: fileName,
            verificationSessionToken: this.state.verificationId,
            url: this.state.urlVideo
        }
        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then( responseJson => {
                this.verificationResult();
            })
            .catch((err) => {
                console.log(err);
                this.updateOnResponse({
                    success: false,
                    error:'An error has occurred, please try again later'
                });
            });
    }

    // verification X-Hashcash start
    verificationXHashcashStart = (fileSend = false) => {
        if (!fileSend) {
            if (this.form.querySelector('input[type=file]').files.length > 0) {
                fileSend = this.form.querySelector('input[type=file]').files[0];
            } else if (this.droppedFiles) {
                fileSend = this.droppedFiles;
            }
        }

        if (fileSend) {
            this.getLinkFileLocation(fileSend);
            this.getSha256(fileSend)
            .then(fileSha256Hash => {

                getHashCashTask(fileSha256Hash, SERVICE_VERIFICATION)
                .then(XHashcash => {

                const url = URL_API + "/verification/Verification/CreateVerificationSession";
                const data = {
                    fileName: fileSend.name,
                    fileSha256Hash: fileSha256Hash,
                    type: this.getVerificationType() === TYPE_ANTIFAKE ? "cut-detect" : "prover",
                }
                let options = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Hashcash': XHashcash
                    }
                };
                fetch(url, options)
                    .then(response => response.json())
                    .then( responseJson => {
                        this.setState({
                            verificationId: responseJson.data.verificationSessionToken,
                        });
                        this.handleSubmitVer();
                    })
                    .catch((err) => {
                        console.log(err);
                        this.updateOnResponse({
                            success: false,
                            error:'An error has occurred, please try again later'
                        });
                    });
                });
            });
        }
    }

    // verification X-Recaptcha start
    verificationStart = recaptchaResponse => {
        this.setState({hideBlockDrag: false});
        this.context.setVerificationProcess(true);
        
        let fileSend = false;
        if (this.form.querySelector('input[type=file]').files.length > 0) {
            fileSend = this.form.querySelector('input[type=file]').files[0];
        } else if (this.droppedFiles) {
            fileSend = this.droppedFiles;
        }
        if (fileSend) {
            this.getLinkFileLocation(fileSend);
            this.getSha256(fileSend)
            .then(fileSha256Hash => {
                const url = URL_API + "/verification/Verification/CreateVerificationSession";
                const data = {
                    fileName: fileSend.name,
                    fileSha256Hash: fileSha256Hash,
                    type: this.getVerificationType() === TYPE_ANTIFAKE ? "cut-detect" : "prover",
                }
                let options = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Recaptcha': recaptchaResponse,
                    }
                };
                fetch(url, options)
                    .then(response => response.json())
                    .then( responseJson => {
                        this.setState({
                            verificationId: responseJson.data.verificationSessionToken,
                            hiddenCaptcha: 1,
                        });
                        this.handleSubmitVer();
                    })
                    .catch((err) => {
                        console.log(err);
                        this.updateOnResponse({
                            success: false,
                            error:'An error has occurred, please try again later'
                        });
                    });

                });
        }
    }

    // verification result
    verificationResult = (t = 0) => {
        // console.log('verificationResult() seconds passed - ', t / 1000);
        const url = URL_API + "/verification/Verification/VerificationResult?GenerateCertificate=true&VerificationSessionToken=" + this.state.verificationId;
        const interval = 5000;
        let options = {
            method: 'GET',
        };
        fetch(url, options)
            .then(response => response.json())
            .then(verification_result_response => {
                if (this.state.addNotice !== '') {
                    this.setState({addNotice: ''});
                }

                if (!verification_result_response.data.final) {
                    setTimeout(() => {
                        this.verificationResult(t + interval);
                    }, interval);
                } else {
                    this.updateOnResponse(verification_result_response);
                }
            })
            .catch((err) => {
                this.setState({addNotice: 'Connection problem'});
                setTimeout(() => {
                    this.verificationResult(t + interval);
                }, interval);
            });
    };

    // Проверка видео по ссылке
    handleChangeUrlVideo(e) {
        this.setState({urlVideo: e.target.value});
    }

    // video-downloader start
    videoDownloaderStart = () => {
        if (!this.state.urlVideo) {
            this.updateOnResponse({
                success: false,
                error: 'Url is empty'
            });
            return;
        }
        this.verifyVideoByUrl();
        getHashCashTask(this.state.urlVideo.replace('https://', ''), SERVICE_DOWNLOADS)
            .then(XHashcash => {
                const url = URL_API + "/video-downloader/Downloads/Start";
                const data = {
                //   "quality": "",
                //   "maximumSize": 0,
                    "url": this.state.urlVideo
                }
                let options = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Hashcash': XHashcash
                    }
                };
                fetch(url, options)
                    .then(response => response.json())
                    .then( responseJson => {
                        if (responseJson.error) {
                            throw 'Can not download video from this url: ' + this.state.urlVideo;
                        }
                        if (responseJson.data) {
                            this.getStatusVideoByUrl(responseJson.data.id);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.updateOnResponse({
                            success: false,
                            error: err ? err : 'An error has occurred, please try again later'
                        });
                    });
            });
        return;
    }

    simulationUpload() {
        this.simulationPerc = 0;
        this.simulationTime = 10;
        const form = this.form;
        let uploadBackground = form.querySelector('.upload-background');
        let progress = form.querySelector('.progress');
        const simulation = () => {
            uploadBackground.style.width = this.simulationPerc + '%';
            progress.innerHTML = Math.round(this.simulationPerc) + ' %';
            this.simulationTime -= 0.1;
            this.setState({secondsRemaining: Math.round(this.simulationTime)});
            if (this.simulationPerc < 100) {
                this.simulationPerc++;
                setTimeout(simulation, 100);
            } else {
                this.setState({uploadingFile: true});
            }
        };
        simulation();
    }

    checkKeyForm(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    anotherFileTouchStart = () => {
        if('ontouchstart' in document.documentElement) {
            return true
        }
        return false
    }

    statusLineColor = () => {
        let { finished, uploadingFile, isError } = this.state;
        let { dataForm } = this.props;
        let { prover, clapperboard, va, detail } = this.analyzeData;
        let color = '';
        if(dataForm !== 'Antifake') {
            if (va && va.cutdetect && !va.cutdetect.length) {
                if (prover.reference || prover.submit || clapperboard.qrcode) {
                    color = 'green';
                }
            } else if (va === null && !prover.reference && !prover.submit && clapperboard.qrcode) {
                color = 'green';
            } else if (prover && prover.reference && prover.submit) {
                if (va && va.cutdetect && va.cutdetect.length || !prover.swypeSearch.found) {
                    color = 'yellow';
                }
            } else if (isError || prover && prover.reference === null && prover.submit === null) {
                color = 'red';
            } else {
                color = 'gray';
            }
        } else {
            if(this.analyzeSuccess && va && va.cutdetect  && !va.cutdetect.length && uploadingFile) {
                color = 'green';
            } else if (this.analyzeSuccess && va && va.cutdetect && va.cutdetect.length && uploadingFile) {
                color = 'yellow';
            } else if (isError && uploadingFile) {
                color = 'red';
            } else {
                color = 'gray';
            }
        }

        if (color !== 'gray') {
            return (
                <React.Fragment>
                    <div className={`${color}-line`}></div>
                    <p className={`${color}-line-description`}>{
                            `${color === 'red' ? 'fault' : 'success'}`
                    }</p>
                </React.Fragment>
            )
        } else {
            return (
                <div className={`${color}-line`}></div>
            )
        }
    }

    getLinkFileLocation = (fileSend) => {
        const app = this;
        EXIF.getData(fileSend, function() {
            const allMetaData = EXIF.getAllTags(this);
            if (allMetaData.GPSLatitude && allMetaData.GPSLongitude) {
                const GPSLatitude = `${allMetaData.GPSLatitude[0]}°${allMetaData.GPSLatitude[1]}'${allMetaData.GPSLatitude[2]}`;
                const GPSLongitude = `${allMetaData.GPSLongitude[0]}°${allMetaData.GPSLongitude[1]}'${allMetaData.GPSLongitude[2]}`;
                app.setState({
                    hrefMap: `https://www.google.com/maps/place/${GPSLatitude }"N${GPSLongitude}"E`,
                    noteLocation: `Location: ${GPSLatitude }"N ${GPSLongitude}"E`
                })
            } else {
                app.setState({
                    hrefMap: null,
                    noteLocation: ''
                })
            }
        });
    }

    showAlertForIos = () => {
        if (isIOS) {
            alert("Upload file from the 'Files' folder to avoid file corruption.");
        }
    }

    render() {
        let arrayTypeFile = '';
        let lengthArrayType = this.state.dataForm.fileExtensions.length;
        this.state.dataForm.fileExtensions.forEach(function (type, index) {
            arrayTypeFile += lengthArrayType - 1 === index ? '.' + type : '.' + type + ',';
        });
        let analyze_paragraphes = [];
        let analyze_paragraphes_buttons = [];
        if (!this.analyzeData) {
            this.analyzeData = {};
        }
        analyze_paragraphes.push(<p key="analyzeData-proven" className="p_info">
            Proven: <span>{this.analyzeData.proven ? 'Yes' : 'No'}</span>
        </p>);
        if (this.analyzeData.error) {
            analyze_paragraphes.push(<p key="analyzeData-error" className="p_info">
                Message: <span>{this.analyzeData.error}</span>
            </p>);
        }
        if (this.analyzeData.detail) {
            analyze_paragraphes.push(<p key="analyzeData-detail" className="p_info">
                Detail: <span>{this.analyzeData.detail}</span>
            </p>);
        }
        if (this.analyzeData.prover && this.analyzeData.prover.mediaHash) {
            analyze_paragraphes.push(<p key="analyzeData-mediaHash" className="p_info">
                Media hash: <span>{this.analyzeData.prover.mediaHash}</span>
            </p>);
        }
        if (this.analyzeData.prover && this.analyzeData.prover.submit && this.analyzeData.prover.submit.transactionInfo) {
            analyze_paragraphes.push(<p key="analyzeData-submitMediaHash-transactionHash" className="p_info">
                Transaction hash of media submitting:
                <span><a target="_blank" href={'http://explorer.nemtool.com/?#/s_tx?hash=' + this.analyzeData.prover.submit.transactionInfo.transactionHash}>
                    {this.analyzeData.prover.submit.transactionInfo.transactionHash}
                </a></span>
            </p>);
            analyze_paragraphes.push(<p key="analyzeData-submitMediaHash-blockTimestamp" className="p_info">
                Date-time of media submitting:
                <span>{dateStrFromTs(this.analyzeData.prover.submit.transactionInfo.blockInfo.blockTimestamp)}</span>
            </p>);
            if (this.analyzeData.prover.swypeSearch && this.analyzeData.prover.swypeSearch.searched) {
                analyze_paragraphes.push(<p key="analyzeData-swype" className="p_info">
                    Swype code: <span>{this.analyzeData.prover.reference.swype}</span>
                </p>);
                if (this.analyzeData.prover.swypeSearch.found) {
                    analyze_paragraphes.push(<p key="analyzeData-swypeBeginEnd" className="p_info">
                        Swype begin/end:
                        <span>{this.analyzeData.prover.swypeSearch.swypeCodeBeginOffset}/{this.analyzeData.prover.swypeSearch.swypeCodeEndOffset}</span>
                    </p>);
                } else {
                    analyze_paragraphes.push(<p key="analyzeData-swypeBeginEnd" className="p_info">
                        Swype begin/end:
                        <span>not found</span>
                    </p>);
                }
                let prover_reference_blockInfo_blockTimestamp = 0;
                if (this.analyzeData.prover.reference.transactionInfo) {
                    analyze_paragraphes.push(<p key="analyzeData-swype-reference-transactionHash" className="p_info">
                        Transaction hash of swype:
                        <span><a target="_blank" href={'http://explorer.nemtool.com/?#/s_tx?hash=' + this.analyzeData.prover.reference.transactionInfo.transactionHash}>
                            {this.analyzeData.prover.reference.transactionInfo.transactionHash}
                        </a></span>
                    </p>);
                    prover_reference_blockInfo_blockTimestamp = this.analyzeData.prover.reference.transactionInfo.blockInfo.blockTimestamp;
                } else {
                    analyze_paragraphes.push(<p key="analyzeData-swype-reference-blockHeight" className="p_info">
                        Block height of swype:
                        <span><a target="_blank" href={'http://explorer.nemtool.com/?#/s_block?height=' + this.analyzeData.prover.reference.blockInfo.blockHeight}>
                            {this.analyzeData.prover.reference.blockInfo.blockHeight}
                        </a></span>
                    </p>);
                    prover_reference_blockInfo_blockTimestamp = this.analyzeData.prover.reference.blockInfo.blockTimestamp;
                }
                analyze_paragraphes.push(<p key="analyzeData-swypeDatetime" className="p_info">
                    Date-time of swype:
                    <span>{dateStrFromTs(prover_reference_blockInfo_blockTimestamp)}</span>
                </p>);
            } else {
                analyze_paragraphes.push(<p key="analyzeData-noswype" className="">
                    Media submitted without swype
                </p>);
            }
        }
        if (this.analyzeData.clapperboard && this.analyzeData.clapperboard.submit && this.analyzeData.clapperboard.submit.transactionInfo) {
            analyze_paragraphes.push(<p key="analyzeData-submitMessage-message" className="p_info">
                Submitted message:
                <span>{this.analyzeData.clapperboard.submit.message}</span>
            </p>);
            analyze_paragraphes.push(<p key="analyzeData-submitMessage-transactionHash" className="p_info">
                Transaction hash of message submitting:
                <span><a target="_blank" href={'http://explorer.nemtool.com/?#/s_tx?hash=' + this.analyzeData.clapperboard.submit.transactionInfo.transactionHash}>
                            {this.analyzeData.clapperboard.submit.transactionInfo.transactionHash}
                </a></span>
            </p>);
            analyze_paragraphes.push(<p key="analyzeData-submitMessage-blockTimestamp" className="p_info">
                Date-time of message submitting:
                <span>{dateStrFromTs(this.analyzeData.clapperboard.submit.transactionInfo.blockInfo.blockTimestamp)}</span>
            </p>);
            analyze_paragraphes.push(<p key="analyzeData-submitMessage-reference-blockHeight" className="p_info">
                Block height of message reference:
                <span><a target="_blank" href={'http://explorer.nemtool.com/?#/s_block?height=' + this.analyzeData.clapperboard.reference.blockInfo.blockHeight}>
                        {this.analyzeData.clapperboard.reference.blockInfo.blockHeight}
                    </a></span>
            </p>);
            analyze_paragraphes.push(<p key="analyzeData-submitMessage-reference-blockTimestamp" className="p_info">
                Date-time of message reference:
                <span>{dateStrFromTs(this.analyzeData.clapperboard.reference.blockInfo.blockTimestamp)}</span>
            </p>);
        }
        if (this.analyzeData.va && this.analyzeData.va.cutdetect) {
            if (this.analyzeData.va.cutdetect.length) {
                analyze_paragraphes.push(<p key="analyzeData-va-cutdetect" className="p_info">
                    Detected file montage (seconds of video):
                    <span>{this.analyzeData.va.cutdetect.join(', ')}</span>
                </p>);
            } else {
                analyze_paragraphes.push(<p key="analyzeData-va-cutdetect" className="">
                    File montage not found
                </p>);
            }
        }
        if (this.certificateUrl) {
            analyze_paragraphes_buttons.push(
                <a key="analyzeData-pdf" target="_blank" href={this.certificateUrl} className="download-pdf upload-red-button">
                    Download .pdf certificate
                </a>
            );
        }
        return (
            <section id={this.state.dataForm.secId} className="upload-file">
                <div className="container">
                    <h3>Check file</h3>
                    <p className="type-description">{this.showSubtitle()}</p>
                    <form onSubmit={this.handleSubmit} method="post" id={this.state.dataForm.formId} action={this.state.formAction} encType="multipart/form-data" onKeyDown={this.checkKeyForm} noValidate
                          className={classNames({
                              'box': true,
                              'has-advanced-upload': this.isAdvancedUpload,
                              'is-uploading': this.state.isUploading,
                              'is-error': this.state.isError,
                              'is-success': this.state.isSuccess,
                              'is-result': this.state.isResult,
                          })}>
                        <div className={classNames({
                            'box__captcha': true,
                            'hidden': this.state.hiddenCaptcha,
                        })}>
                            <div className="captcha" id="grecaptcha-block"></div>
                        </div>

                        <div className={!this.state.finished ? "box__input" : ""} onClick={this.handleClickFile} hidden={this.state.finished}>
                            {this.statusLineColor()}
                            <h3 className="box__verification">{this.state.dataForm.textForm}</h3>
                            <div className="block__button">
                                <input onChange={this.handleChange} type="file" name="file" id="file" className="box__file" accept={arrayTypeFile} {...(isIOS ? {multiple: "multiple"} : {})}/>
                                <label className="box__labelFile_default" htmlFor="" onClick={this.showAlertForIos}>
                                    <strong>Choose a file</strong>
                                    <span className="box__dragndrop"> or drag it here.</span>
                                </label>
                                {isIOS && <p className="forIosFiles">Upload a file from the "Files" folder.</p>}
                                <label className="box__labelFile_file" htmlFor=""></label>
                                <button type="submit" className="box__button">Upload</button>
                            </div>
                        </div>
                        <iframe
                            className={
                                classNames({
                                    'blockDrag': true,
                                    'hidden': this.state.hideBlockDrag
                                })
                            }
                        />
                        <div className="box__uploading">
                            <div className="upload-background"></div>
                            <div className="upload">
                                {this.statusLineColor()}
                                <p className="progress">0%</p>
                                <h3 className="box__verification">{(this.state.uploadingFile) ? 'File upload is complete. Analysis is in progress' : 'Uploading...'}</h3>
                                <p className="add-notice">{(this.state.addNotice !== '') ? this.state.addNotice : ''}</p>
                                <img src={gear} alt="#"/>
                                <p className="name-file">{this.state.fileName}</p>
                                <p className="size-file">{this.state.fileSize}</p>
                                <p className="note-load-file">Download start: {this.state.fileDownloadStart}</p>

                                <p className={classNames({
                                    'note-load-file': true,
                                    'hidden': this.state.uploadingFile,
                                })}>{(this.state.secondsRemaining) ? this.state.secondsRemaining + ' seconds remaining' : ''} </p>
                            </div>
                        </div>
                        <div className="box__result" hidden={!this.state.finished}>
                            {this.statusLineColor()}
                            {!this.state.isError ? <h3 className="box__verification">Done!</h3> : ''}
                            {this.state.isError ? <h3 className="box__verification error">{this.state.textError}</h3> : ''}
                            <div className="result_information">
                                {!this.state.isError && analyze_paragraphes}
                                <div className="buttons">
                                    {analyze_paragraphes_buttons}
                                    <strong onClick={this.handleClickFile} className="upload-red-button" onTouchStart={this.anotherFileTouchStart}>
                                        Try another file
                                    </strong>
                                </div>
                                {this.state.hrefMap ?
                                    <div className="buttons location-button">
                                        <a className="upload-red-button" target="_blank" rel="noopener noreferrer" href={this.state.hrefMap}>{this.state.noteLocation}</a>
                                    </div> :
                                    null
                                }
                            </div>
                        </div>

                        {config.checkVideoByUrl ?
                            <CheckVideoByUrl
                                urlVideo={this.state.urlVideo}
                                isVideoByUrl={this.state.isVideoByUrl}
                                messageVideoByUrl={this.state.messageVideoByUrl}
                                handleChangeUrlVideo={this.handleChangeUrlVideo}
                                videoDownloaderStart={this.videoDownloaderStart}
                            />
                            : ''
                        }
                    </form>
                </div>
            </section>
        );
    }
}


export default DownloadFile;
