import * as React from 'react';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import $ from "jquery";
import s from './ViewImage.module.scss';

class ViewImage extends React.Component {
    state = {
        fullscreen: false
    };

    switchFullscreen = () => this.setState({fullscreen: !this.state.fullscreen});

    componentDidMount() {
        $(this.container).on('contextmenu', 'img', function() {
            return false;
        })
    }

    render() {
        const {src} = this.props;

        if (!src) {
            return null;
        }

        const {fullscreen} = this.state;

        return <div className={s.container} ref={e => this.container = e}>
            <img src={src} onClick={this.switchFullscreen} />
            {fullscreen && <Lightbox image={src} onClose={this.switchFullscreen} />}
        </div>;
    }
}

export default ViewImage;
