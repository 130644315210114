import '../styles/clapperboard-afp.css';

import * as React from 'react';
import {Component} from 'react';
import {config} from '../config.js'
const URL_API = config.apiUrl;


class ClapperboardAFP extends Component {
    constructor(props) {
        super(props);

        this.authorization = this.authorization.bind(this);
        this.generationPublicKey = this.generationPublicKey.bind(this);
        this.selectForm = this.selectForm.bind(this);
        this.closeSession = this.closeSession.bind(this);
        this.generationQRCode = this.generationQRCode.bind(this);

        this.balanceUser = '';
        this.publicKey = localStorage.public_key;
        if (localStorage.session_key) {
            this.renewSession();
        }
        if (!this.publicKey) {
            this.publicKey = this.generationPublicKey();
            localStorage.setItem('public_key', this.publicKey);
        }

        this.state = {
            form: this.selectForm(localStorage.session_key),
        };
    }

    closeSession (event) {
        event.preventDefault();
        const App = this;
        let ajax = new XMLHttpRequest();
        let form = document.querySelector('.clapperboard-afp form.logout');
        let ajaxData = new FormData(form);
        ajax.open(form.getAttribute('method'), form.getAttribute('action'), true);
        ajax.onload = function () {
            if (ajax.status >= 200 && ajax.status < 400) {
                try {
                    let response = JSON.parse(ajax.responseText);
                    if (response.success) {
                        localStorage.removeItem('session_key');
                        App.setState({form: App.selectForm('')});
                    }
                } catch (exception) {

                }
            } else {
                console.log('status');
                console.log(ajax.status);
            }
        };

        ajax.onerror = function () {
            console.log('Error. Please, try again!');
        };
        ajax.send(ajaxData);
    }

    generationQRCode (event) {
        event.preventDefault();
        const App = this;
        let message = document.querySelector('p.error');
        let btn = document.querySelector('form.generation.qr .btn');
        btn.classList.add('hidden');
        let offer_id = '',
            inputStr =
                document.querySelector('input[name=artist_name]').value + ' ' +
                document.querySelector('input[name=afp]').value + ' ' +
                document.querySelector('input[name=gps_marker]').value,
            success = true;
        let ajaxData = new FormData();
        ajaxData.append("type", "qr");
        ajaxData.append("session_key", localStorage.session_key);
        let ajax = new XMLHttpRequest();
        ajax.open('post', URL_API + '/prover/offer', false);
        ajax.onload = function () {
            if (ajax.status >= 200 && ajax.status < 400) {
                try {
                    let response = JSON.parse(ajax.responseText);
                    success = response.success;
                    if (response.success)
                        offer_id = response.offer_id;
                    else
                        message.innerHTML = response.error;
                } catch (exception) {
                    message.innerHTML = exception;
                }
            } else {
                message.innerHTML = ajax.status;
                console.log(ajax.status);
            }
        };

        ajax.onerror = function () {
            message.innerHTML = 'Error. Please, try again!';
            console.log('Error. Please, try again!');
        };
        ajax.send(ajaxData);

        if (success) {
            ajaxData = new FormData();
            ajaxData.append("message", inputStr);
            ajaxData.append("type", "qr");
            ajaxData.append("offer_id", offer_id);
            ajaxData.append("session_key", localStorage.session_key);

            ajax.open('post', URL_API + '/prover/confirm', false);
            ajax.onload = function () {
                if (ajax.status >= 200 && ajax.status < 400) {
                    try {
                        let response = JSON.parse(ajax.responseText);
                        success = response.success;
                        if (!success)
                            message.innerHTML = response.error;
                    } catch (exception) {
                        message.innerHTML = exception;
                    }
                } else {
                    message.innerHTML = ajax.status;
                    console.log(ajax.status);
                }
            };

            ajax.onerror = function () {
                message.innerHTML = 'Error. Please, try again!';
                console.log('Error. Please, try again!');
            };
            ajax.send(ajaxData);
        }

        let txhash = '';
        let blocksignature = '';
        if (success) {
            let message_confirmed_placed = 0;
            ajaxData = new FormData();
            ajaxData.append("message", inputStr);
            ajaxData.append("type", "qr");
            ajaxData.append("offer_id", offer_id);
            ajaxData.append("session_key", localStorage.session_key);

            let request = setInterval(function() {
                ajax.open('post', URL_API + '/prover/result', false);
                ajax.onload = function () {
                    if (ajax.status >= 200 && ajax.status < 400) {
                        try {
                            let response = JSON.parse(ajax.responseText);
                            if (response.success) {
                                message_confirmed_placed = response.message_confirmed_placed;
                                txhash = response.message_txhash;
                                blocksignature = response.message_blocksignature;
                            }
                        } catch (exception) {

                        }
                    } else {
                        console.log('status');
                        console.log(ajax.status);
                    }
                };

                ajax.onerror = function () {
                    console.log('Error. Please, try again!');
                };
                ajax.send(ajaxData);
                if (message_confirmed_placed) {
                    clearInterval(request);
                    App.requestQRcode(txhash, blocksignature, inputStr);
                }

            }, 10000);
        }
    }

    requestQRcode (txhash, blocksignature, inputStr) {
        let ajax = new XMLHttpRequest();
        let srcImg = '';
        let ajaxData = new FormData();
        ajaxData.append("txhash", txhash);
        ajaxData.append("blocksignature", blocksignature);
        ajax.open('post', URL_API + '/fileverify/qrcode', false);
        ajax.onload = function () {
            if (ajax.status >= 200 && ajax.status < 400) {
                try {
                    let response = JSON.parse(ajax.responseText);
                    if (response.success)
                        srcImg = response.path;
                } catch (exception) {

                }
            } else {
                console.log('status');
                console.log(ajax.status);
            }
        };

        ajax.onerror = function () {
            console.log('Error. Please, try again!');
        };
        ajax.send(ajaxData);

        let img = document.querySelector('form.generation.qr img');
        let link = document.querySelector('form.generation.qr a');
        document.querySelector('form.generation.pdf input[name=inputStr]').value = inputStr;
        img.classList.remove("hidden");
        img.setAttribute('src', srcImg);
        link.setAttribute('href', srcImg);
    }

    generationQRPdf (event) {
        event.preventDefault();
        let linkHref = '';
        let ajax = new XMLHttpRequest();
        let form = document.querySelector('form.generation.pdf');
        let ajaxData = new FormData(form);
        ajax.open('post', URL_API + '/fileverify/pdfqr', false);
        ajax.onload = function () {
            if (ajax.status >= 200 && ajax.status < 400) {
                try {
                    let response = JSON.parse(ajax.responseText);
                    if (response.success)
                        linkHref = response.path;
                } catch (exception) {

                }
            } else {
                console.log('status');
                console.log(ajax.status);
            }
        };

        ajax.onerror = function () {
            console.log('Error. Please, try again!');
        };
        ajax.send(ajaxData);

        let link = document.querySelector('form.generation.pdf a');
        link.classList.remove("hidden");
        link.setAttribute('href', linkHref);
    }

    selectForm (sessionKey) {
        if (sessionKey)
            return (
                <div className="row">
                    <label>Balance: {this.balanceUser}</label><br/><br/>
                    <form onSubmit={this.generationQRCode} action="" method="post" className="generation qr">
                        Artist name <input type="text" name="artist_name"/>
                        Festival name <input type="text" name="afp" defaultValue="Alfa future people 2018"/>
                        GPS marker <input type="text" name="gps_marker"/>
                        <a download><img src="" alt="qr code" className="hidden"/></a>
                        <input type="submit" value="Generate QR" className="btn"/>
                    </form>
                    <form onSubmit={this.generationQRPdf} action="" method="post" className="generation pdf">
                        txhash <input type="text" name="txhash"/>
                        file hash <input type="text" name="hash"/>
                        file name <input type="text" name="file_name"/>
                        <input type="text" name="inputStr" className="hidden"/>
                        <a target="_blank" rel="noopener noreferrer" download className="hidden">Pdf certificate</a>
                        <input type="submit" value="Generate certificate" className="btn"/>
                    </form>
                    <p className="error"></p>
                    <form onSubmit={this.closeSession} action={URL_API + '/account/close_session'} className="logout" method="post">
                        <input type="hidden" name="session_key" className="hidden" defaultValue={sessionKey}/><br/>
                        <input type="submit" value="Log Out" className="btn"/>
                    </form>
                </div>
            );
        else
            return (
                <div className="row">
                    <h3>Authorization</h3>
                    <form onSubmit={this.authorization} action={URL_API + '/account/auth'} method="post">
                        Login<input type="mail" name="login" autoComplete="off"/><br/>
                        Password<input type="password" name="password"/><br/>
                        <input type="hidden" name="public_key" defaultValue={this.publicKey}/><br/>
                        <input type="submit" value="Log In" className="btn"/>
                    </form>
                </div>
            );
    }

    getBalanceUser () {
        let balance = 0;
        let ajaxData = new FormData();
        ajaxData.append('session_key', localStorage.session_key);
        let ajax = new XMLHttpRequest();
        ajax.open('post', URL_API + '/balance/info', false);
        ajax.onload = function () {
            if (ajax.status >= 200 && ajax.status < 400) {
                try {
                    let response = JSON.parse(ajax.responseText);
                    if (response.balance)
                        balance = parseInt(response.balance)/Math.pow(10,response.divisibility);

                } catch (exception) {

                }
            } else {
                console.log('status');
                console.log(ajax.status);
            }
        };

        ajax.onerror = function () {
            console.log('Error. Please, try again!');
        };
        ajax.send(ajaxData);

        return balance;
    }

    renewSession () {
        const App = this;
        let ajaxData = new FormData();
        ajaxData.append('session_key', localStorage.session_key);
        let ajax = new XMLHttpRequest();
        ajax.open('post', URL_API + '/account/renew_session', true);
        ajax.onload = function () {
            if (ajax.status >= 200 && ajax.status < 400) {
                try {
                    let response = JSON.parse(ajax.responseText);
                    if (response.success) {
                        localStorage.setItem('session_key', response.session_key);
                        App.balanceUser = App.getBalanceUser();
                        App.setState({form: App.selectForm(response.session_key)});
                    }
                    else {
                        localStorage.removeItem('session_key');
                        App.setState({form: App.selectForm(localStorage.session_key)})
                    }

                } catch (exception) {
                    localStorage.removeItem('session_key');
                    App.setState({form: App.selectForm(localStorage.session_key)})
                }
            } else {
                console.log('status');
                console.log(ajax.status);
                localStorage.removeItem('session_key');
                App.setState({form: App.selectForm(localStorage.session_key)})
            }
        };

        ajax.onerror = function () {
            console.log('Error. Please, try again!');
            localStorage.removeItem('session_key');
            App.setState({form: App.selectForm(localStorage.session_key)})
        };
        ajax.send(ajaxData);
    }

    generationPublicKey () {
        let publicKey = Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 2);

        console.log('publicKey', publicKey);
        console.log('length publicKey', publicKey.length);

        return publicKey;
    }

    authorization (event) {
        event.preventDefault();
        const App = this;
        let ajax = new XMLHttpRequest();
        let form = document.querySelector('.clapperboard-afp form');
        let ajaxData = new FormData(form);
        ajax.open(form.getAttribute('method'), form.getAttribute('action'), true);
        ajax.onload = function () {
            if (ajax.status >= 200 && ajax.status < 400) {
                try {
                    let response = JSON.parse(ajax.responseText);
                    console.log('response', response);
                    if (response.success) {
                        localStorage.setItem('session_key', response.session_key);
                        App.balanceUser = App.getBalanceUser();
                        App.setState({form: App.selectForm(response.session_key)});
                    }
                } catch (exception) {

                }
            } else {
                console.log('status');
                console.log(ajax.status);
            }
        };

        ajax.onerror = function () {
            console.log('Error. Please, try again!');
        };
        ajax.send(ajaxData);
    }

    render() {
        return (
            <div className="clapperboard-afp">
                {this.state.form}
            </div>
        );
    }
}

export default ClapperboardAFP;