import * as React from 'react';
import {Component} from 'react';
import ReactGA from 'react-ga';
import { YMInitializer } from 'react-yandex-metrika';
import DownloadFile from './DownloadFile';
import Menu from './Menu';
import SectionMainClapperboard from './Section-main-clapperboard';
import SectionWorks from './Section-works';
import SectionInstruction from './Section-instruction';
import SectionTryIt from './Section-try-it';
import Footer from './Footer';

import logo from '../images/logo_bw.svg';
import cb_1 from '../images/1_cb_new.png';
import cb_2 from '../images/2_cb_new.png';
import pr_2 from '../images/2_pr_new.png';
import pr_3 from '../images/3_pr_new.png';
import pr_4 from '../images/4_pr_new.png';
import MobileMenuButton from "./MobileMenuButton/MobileMenuButton";
import ym from "react-yandex-metrika";

class Clapperboard extends Component {
    constructor(props) {
        super(props);
        const menuData = [
            {
                img: false,
                label: "Description",
                link: "#main",
            },
            {
                img: false,
                label: "How it works",
                link: "#instruction",
            },
            {
                img: false,
                label: "Try it now",
                link: "#try-it",
            },
        ];
        const worksData = [
            [{
                text: "The application is created for situations when video recording is conducted on cameras that do not have Internet connection: digital cameras and video cameras, fixed cameras of video surveillance systems and any other digital cameras.",
                imgLink: cb_1
            },
                {
                    text: "The digital signature is based on the generation of a unique QR-code created in blockchain based on random text data entered by the user and timestamps.",
                    imgLink: cb_2
                }],
            [{
                text: "The generated QR-code is displayed on the screen of a smartphone or tablet, after which the user can show it on the video being taken analogous to the director's clapper.",
                imgLink: pr_2
            },
                {
                    text: "Confirmation of the video file is carried out due to its online verification. If the video has a readable QR-code contained in the blockchain and there are no traces of montage the video file is considered authentic and recorded not earlier than the QR code is generated. ",
                    imgLink: pr_3
                }],
            [{
                text: ["For QR-code generation the Internet connection is required. The application supports most modern Android ", <span key="0" className='footnote-star'>**</span>, " and iOS ", <span key="1" className='footnote-star'>***</span>, "- based phones and tablets."],
                imgLink: pr_4
            }]
        ];
        const instructionData = [
            {
                text: "Install the app and sing up your account",
                videoLink: ""
            },
            {
                text: "Recharge your balance",
                videoLink: ""
            },
            {
                text: "Enter your own explanatory comment (it will be saved in blockchain)",
                videoLink: ""
            },
            {
                text: "Get a QR-code and show it to the camera (it should be more than 10% of the picture)",
                videoLink: ""
            },
            {
                text: "Check this very video with Prover online-service",
                videoLink: ""
            }
        ];
        const linkMarket = {
            apple: {link: "https://itunes.apple.com/us/app/prover-clapperboard/id1436945293?l=ru&ls=1&mt=8", description: "For IOS"},
            android: {link: "https://play.google.com/store/apps/details?id=io.prover.clapperboard", description: "For Android"},
        };
        this.state = {
            menuData: menuData,
            instructionData: instructionData,
            linkMarket: linkMarket,
            worksData: worksData,
            titleWorks: "How works PROVER Clapperboard",
            titleInstruction: "PROVER Clapperboard – simple, quick and convenient",
            titleTryIt: "Try PROVER Clapperboard right now"
        };
        if (!this.props.activeItem)
            this.props.initSelectPath(this.props.location.pathname);
    }

    componentDidMount() {
        ReactGA.pageview('/clapperboard');
        ym('hit', '/clapperboard');
    }

    render() {
        return (
            <div id="prover">
                <YMInitializer accounts={[51641141]} options={{webvisor: true}} />
                <Menu menuData={this.state.menuData} logo={logo}/>
                <SectionMainClapperboard linkMarket={this.state.linkMarket}/>
                <SectionWorks worksData={this.state.worksData} titleWorks={this.state.titleWorks}/>
                <SectionInstruction instructionData={this.state.instructionData} titleInstruction={this.state.titleInstruction}/>
                <SectionTryIt linkMarket={this.state.linkMarket} titleTryIt={this.state.titleTryIt}/>
                <DownloadFile dataForm="Clapperboard"/>
                <Footer menuData={this.state.menuData}/>
                <MobileMenuButton />
            </div>
        );
    }
}

export default Clapperboard;
