import React, {Component} from 'react';
import classNames from 'classnames';
import '../../../styles/DownloadFile.css';
import {config} from '../../../config.js'
import fetch from "isomorphic-fetch";
import s from './UploadFile.module.scss';
import {getSha256Async} from "../../../utils/cryptoHelper";
import Uploading from "./Uploading/Uploading";
import Result from "./Result/Result";
import Input from "./Input/Input";
import { AppContext } from '../../../context';

class UploadFile extends Component {
    static contextType = AppContext;

    defaultState = {
        isError: false,
        textError: null,
        finished: false,
        showCaptcha: false,
        isUploading: false,
        isSuccess: false,
        isResult: false,
        uploadingFile: false,
        secondsRemaining: 0,
        progress: 0,
        hideBlockDrag: true
    };

    state = this.defaultState;

    componentDidMount() {
        this.uploadDragDrop();
    }

    uploadDragDrop = () => {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']
            .forEach((event) => {
                this.form.addEventListener(event, (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                });
            });

        ['dragover', 'dragenter'].forEach((event) => {
            this.form.addEventListener(event, () => {
                this.form.classList.add('is-dragover');
            });
        });

        ['dragleave', 'dragend', 'drop'].forEach((event) => {
            this.form.addEventListener(event, () => {
                this.form.classList.remove('is-dragover');
            });
        });

        this.form.addEventListener('drop', (e) => {
            this.file = e.dataTransfer.files[0];
            this.handleSubmit();
        });
    };

    handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        if (!this.fileForUpload) {
            return false;
        }

        const fileExtensions = ['mp4', 'avi', 'quicktime', 'jpeg', 'jpg', 'png', 'mov', 'heic', 'heif'];
        const fileTypes = ['video/mp4', 'video/avi', 'video/quicktime', 'image/jpeg', 'image/png', 'application/pdf'];

        const fileExtension = this.fileForUpload.name.toLowerCase().replace(/^.*\./, '');

        if (!fileTypes.includes(this.fileForUpload.type) && !fileExtensions.includes(fileExtension)) {
            this.analyzeData = null;
            this.setState({
                textError: `Unsupported file format: ${this.fileForUpload.type}`,
                isError: true,
                finished: true
            });
            return false;
        }

        if (this.fileForUpload.size > 1024 * 1024 * 100) {
            this.setState({
                isError: true,
                textError: "The file size is more than 100 Mb.",
                finished: true
            });
            return false;
        }

        this.showCaptcha(config.captchaKey);
    };

    // открытие окна загрузки файла
    handleClickFile = () => {
        this.file = null;
        this.setState({
            isError: false,
            textError: "",
            finished: false
        });
        this.input.click()
    }

    showCaptcha(captcha_key) {
        this.setState({showCaptcha: true});
        try {
            // try reset. for first reset send exception - no client exist
            window.grecaptcha.reset();
        } catch (exception) {
            window.grecaptcha.render('grecaptcha-block', {
                sitekey: captcha_key,
                theme: 'dark',
                callback: this.upload
            });
        }
    }

    get fileForUpload() {
        return this.file || this.form.querySelector('input[type=file]').files[0];
    }

    upload = async () => {
        this.setState(this.defaultState);
        this.setState({hideBlockDrag: false});
        this.context.setVerificationProcess(true);

        let ajaxData = new FormData(this.form);
        ajaxData.append('fileSha256Hash', await getSha256Async(this.fileForUpload));
        if (this.file) {
            ajaxData.append('file', this.file);
        }

        let ajax = new XMLHttpRequest();
        const now = new Date();
        const timeStart = now.getTime();

        this.setState({
            isUploading: true,
            fileName: this.fileForUpload.name,
            fileSize: Number(this.fileForUpload.size / 1048576).toFixed(1) + " Mb",
            fileDownloadStart: ("0" + now.getHours()).slice(-2) + ":" + ("0" + now.getMinutes()).slice(-2) + ":" + ("0" + now.getSeconds()).slice(-2),
        });

        ajax.upload.addEventListener('progress', (e) => {
            const timeLoad = new Date().getTime() - timeStart;
            const progress = e.loaded / e.total;
            const secondsRemaining = Math.round((timeLoad / progress - timeLoad) / 1000);
            this.setState({
                secondsRemaining: secondsRemaining,
                progress
            });
        });

        ajax.open("POST", `${config.apiUrl}/drop/Drop/Upload`, true);
        ajax.setRequestHeader('X-Recaptcha', window.grecaptcha.getResponse());
        ajax.onload = () => {
            if (ajax.status >= 200 && ajax.status < 400) {
                try {
                    const verification_send_file_response = JSON.parse(ajax.responseText);
                    this.setState({data: verification_send_file_response.data})
                } catch (exception) {
                }

            } else {
                const verification_send_file_response = JSON.parse(ajax.responseText);
                this.setState({
                    textError: verification_send_file_response.error.message || 'File processing failed.',
                    isError: true,
                    isUploading: false,
                    isResult: true,
                    hideBlockDrag: true,
                    analyzeData: null
                });
                return;
            }

            this.verificationResult();
        };

        ajax.onerror = () => {
            this.form.classList.remove('is-uploading');
            alert('Error. Please, try again!');
            this.setState({
                isUploading: false,
            })
        };
        ajax.send(ajaxData);
        this.file = null;
    };

    verificationResult = (t = 0) => {
        const url = `${config.apiUrl}/drop/Drop/Status?FileId=${this.state.data.fileId}`;
        const interval = 1000;
        let options = {
            method: 'GET',
        };
        fetch(url, options)
            .then(response => response.json())
            .then(verification_result_response => {
                this.setState({addNotice: null, data: verification_result_response.data || {}});

                if (verification_result_response.data.status !== 'ready') {
                    if (verification_result_response.data.status === 'error') {
                        this.setState({
                            textError: 'File processing failed.',
                            isError: true,
                            isUploading: false,
                            isResult: true
                        });
                    } else {
                        setTimeout(() => {
                            this.verificationResult(t + interval);
                        }, interval);
                    }
                } else {
                    this.updateOnResponse(verification_result_response);
                }
            })
            .catch((err) => {
                this.setState({addNotice: 'Connection problem'});
                console.log(err);
                setTimeout(() => {
                    this.verificationResult(t + interval);
                }, interval);
            });
    };

    updateOnResponse(response) {
        this.setState({hideBlockDrag: true});
        this.context.setVerificationProcess(false);
        this.form.reset();
        let analyzeData = {};

        if (!(response instanceof Object)) {
            analyzeData = {
                'error': 'Something went wrong'
            };
        }
        if (!response.success) {
            analyzeData = {
                'error': response.error
            };
        }
        if ('data' in response) {
            analyzeData = response.data.verificationResult || {};
        }
        if ('proven' in analyzeData) {
            if (analyzeData.proven) {
                this.setState({isSuccess: true, isError: false});
            } else if (analyzeData.prover && analyzeData.prover.mediaHash && analyzeData.prover.submit !== 'pending' && analyzeData.clapperboard && analyzeData.clapperboard.submit !== 'pending') {
                this.setState({textError: 'File not found. Information may not be available immediately and if you are sure that the file information has been sended, please check again in a few minutes.'});
            }
        }
        if ('va_cutdetect_clear' in analyzeData) {
            this.setState({isSuccess: true, isError: false});
        }
        this.setState({
            analyzeData,
            isResult: false,
            isUploading: false,
            finished: true,
            hrefMap: analyzeData.geolocation ? `https://www.google.com/maps/place/${analyzeData.geolocation.latitude}N${analyzeData.geolocation.longitude}E` : this.state.hrefMap,
            noteLocation: analyzeData.geolocation ? `Location: ${analyzeData.geolocation.latitude}N ${analyzeData.geolocation.longitude}E` : this.state.noteLocation
        });
    }

    render() {
        const {
            isUploading, isError, isSuccess, isResult, showCaptcha, finished
        } = this.state;

        return (
            <section id="upload-file" className="upload-file">
                <div className="container">
                    <h3>Prover Drop</h3>
                    <p className="type-description">Upload file</p>
                    <form
                        ref={e => this.form = e}
                        id="drop-uploadForm"
                        onSubmit={this.handleSubmit}
                        className={classNames(
                            'box',
                            'has-advanced-upload',
                            s.form, {
                                'is-uploading': isUploading,
                                'is-error': isError,
                                'is-success': isSuccess,
                                'is-result': isResult
                            }
                        )}>
                        <span className={`${isError ? 'red' : finished ? 'green' : 'gray'}-line`}/>
                        <div className={classNames({
                            'box__captcha': true,
                            'hidden': !showCaptcha,
                        })}>
                            <div className="captcha" id="grecaptcha-block"/>
                        </div>
                        <iframe
                            className={
                                classNames({
                                    'blockDrag': true,
                                    'hidden': this.state.hideBlockDrag
                                })
                            }
                        />
                        <Input {...this.state} inputRef={e => this.input = e} handleSubmit={this.handleSubmit}/>
                        <Uploading {...this.state} />
                        <Result {...this.state} uploadAnother={this.handleClickFile}/>
                    </form>
                </div>
            </section>
        );
    }
}


export default UploadFile;
