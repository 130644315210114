import * as React from 'react';
import {Component} from 'react';
import '../styles/reset-password.css';
import {config} from '../config.js'
import fetch from 'isomorphic-fetch';
import classNames from 'classnames';
import preloader from '../images/preloader.gif';

const URL_API = config.apiUrl;
const URL_FRONT = config.frontUrl;
const RECAPTCHA_KEY = config.captchaKey
const qs = require('query-string');

class ResetPasswordConfirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: qs.parse(this.props.location.search).token,
            email: qs.parse(this.props.location.search).email,
            hideSubmitBtn: 1,
            hidePreloader: 0,
            hideCaptcha: 0,
            alertText: '',
            notification: '',
        }
    }
    
    componentDidMount() {
        setTimeout(() => {
            this.showCaptcha(RECAPTCHA_KEY);
        }, 3000)
    }
    
    showCaptcha(captcha_key) {
        this.setState({
            hidePreloader: 1,
            hideSubmitBtn: 0,
        })
        try {
            // try reset. for first reset send exception - no client exist
            window.grecaptcha.reset();
        } catch (exception) {
            window.grecaptcha.render('grecaptcha-block', {
                sitekey: captcha_key,
                theme: 'dark',
                callback: () => {
                    this.setState({
                        hideSubmitBtn: 0
                    })
                }
            });
        }
    }

    setPassword = () => {

        let captcha = document.getElementById('g-recaptcha-response');

        if (!captcha) {
            this.setState({
                alertText: `Captcha error`,
            });
            return;
        } else {
            captcha = captcha.value;
        }

        const url = URL_API + '/users/Authentication/ResetPassword';
        const data = {
            token: this.state.token,
            email: this.state.email,
        }
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Recaptcha': captcha,
            }
        };
        fetch(url, options)
        .then(response => response.json())
        .then(set_password_response => {
            if (set_password_response.data) {
                this.setState({
                    hideSubmitBtn: 1,
                    hideCaptcha: 1,
                    alertText: '',
                    notification: 'Password successfully changed',
                });
            } else {
                this.setState({
                    alertText: `An error has occurred: ${set_password_response.error.message}`,
                });   
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    

    render () {
        const { form } = this.state;
        return (
            <div>
                <div
                    className = {classNames({
                        preloaderSection: 1,
                        hidden: this.state.hidePreloader,
                    })}
                >
                    <img alt="preloader" src={preloader}/>
                </div>

                <div className="reset-password">
                    <button
                        className = {classNames({
                            'btn': 1,
                            'btn-reset': 1,
                            'hidden': this.state.hideSubmitBtn, 
                        })}
                        onClick={this.setPassword}
                    >
                        Finish the password change
                    </button>

                    <div
                        className={classNames({
                            captcha: 1,
                            hidden: this.state.hideCaptcha,
                        })}
                        id="grecaptcha-block"
                    ></div>

                    <p className={classNames({
                        'alert-input': 1,
                        'hidden': !this.state.alertText, 
                    })}>{this.state.alertText}</p>
                    <div
                    className = {classNames({
                        notification: 1,
                        hidden: !this.state.notification,
                    })}>
                          <p>{this.state.notification}</p>
                          <p><a href={URL_FRONT}>product.prover.io</a></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPasswordConfirm;