import * as React from 'react';
import {Component} from 'react';

import '../styles/section-main.css';

import googleplay_white from '../images/googleplay_white.svg';
import appstore_white from '../images/appstore_white.svg';
import phone_swype_id from '../images/phone_swype_id.png';
import phone_clapperboard_back from '../images/phone_clapperboard_back.png';

class SectionMainDev extends Component {
    render() {
        return (
            <section id="main" className="main">
                <div className="container">
                    <hr className="left"/>
                    <p className="footnote">* by the results of market research</p>
                    <div className="description">
                        <div className="description-wrapper">
                            <div className="block-description">
                                <h4>The world's first <span className="footnote-star">*</span> blockchain-based application for independent objective confirmation of the events` authenticity taken on the smartphone or tablet camera!</h4>
                            </div>
                            <div className="block-description">
                                <b>IOS app coming soon 15.08.2018</b>
                                {/*<p>Install the application:</p>*/}
                                <div className="block-market_link">
                                    <a className="market_link disabled" target="_blank" rel="noopener noreferrer"><img src={appstore_white} alt=""/></a>
                                    <a href={this.props.linkMarket.android} className="market_link" target="_blank" rel="noopener noreferrer"><img src={googleplay_white} alt=""/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-phone">
                        <img className="img-back" src={phone_clapperboard_back} alt=""/>
                        <img className="img-front" src={phone_swype_id} alt=""/>
                    </div>
                    <hr className="right"/>
                </div>
            </section>
        );
    }
}

export default SectionMainDev;