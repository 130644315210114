import * as React from 'react';
import {Component} from 'react';
import '../styles/reset-password.css';
import {config} from '../config.js'
import fetch from 'isomorphic-fetch';
import classNames from 'classnames';
import preloader from '../images/preloader.gif';

const URL_API = config.apiUrl;
const URL_FRONT = config.frontUrl;
const RECAPTCHA_KEY = config.captchaKey
const qs = require('query-string');

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            token: qs.parse(this.props.location.search).token,
            email: qs.parse(this.props.location.search).email,
            hideForm: 0,
            hideSubmitBtn: 0,
            hidePreloader: 1,
            alertEmail: '',
            alertPassword: '',
            form: {
                email: '',
                password: '',
            }
        }
    }
    
    componentDidMount() {
        setTimeout(() => {
            this.showCaptcha(RECAPTCHA_KEY);
        }, 3000)
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        this.setState({
            hideSubmitBtn: 1,
            hidePreloader: 0,
        });
        const {form} = this.state;
        const email = form.email;
        const new_password = form.password;
        let captcha = document.getElementById('g-recaptcha-response');
        if (!!captcha) {
            captcha = captcha.value;
        }
        const url = URL_API + '/users/Authentication/RequestPasswordReset';
        const data = {
            email: email,
            password: new_password,
            url: URL_FRONT + '/reset-password-confirm'
        }
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Recaptcha': captcha,
            }
        };

        fetch(url, options)
        .then(response => response.json())
        .then(password_recover_response => {
            if (password_recover_response.data) {
                this.setState({
                    hideForm: 1,
                    notification: 'Instructions for resetting the password have been sent to the specified email',
                });
            } else {
                this.setState({
                    hideSubmitBtn: 1,
                    hidePreloader: 1,
                    alertEmail: password_recover_response.error.message,
                });
                this.showCaptcha(RECAPTCHA_KEY);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    
    showCaptcha(captcha_key) {
        try {
            // try reset. for first reset send exception - no client exist
            window.grecaptcha.reset();
        } catch (exception) {
            window.grecaptcha.render('grecaptcha-block', {
                sitekey: captcha_key,
                theme: 'dark',
                callback: () => {
                    this.setState({
                        hideSubmitBtn: 0,
                    })
                }
            });
        }
    }
    
    handleChangeField = ({ target }) => {
        const {form} = this.state;
        this.setState({form: {...form, [target.name]: target.value}});
    }
    
    validateForm = () => {
        let result = true;
        this.setState({
            alertEmail: '',
            alertPassword: '',
        })
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(this.state.form.email) === false) {
            this.setState({
                alertEmail: 'Invalid e-mail',
            })
            result = false;
        }
        if (this.state.form.password.length < 6) {
            this.setState({
                alertPassword: 'The number of characters in the password must be at least six',
            })
            result = false;
        }
        return result
    }

    render () {
        const { form } = this.state;
        return (
            <div className="reset-password">
                <form
                    onSubmit={this.handleSubmit}
                    className={classNames({
                        hidden: this.state.hideForm || this.state.secretKey,
                    })}>
                    <label>Email</label>
                    <input
                        type="mail"
                        name="email"
                        onChange={this.handleChangeField}
                        value={form.email}
                        autoComplete="off"
                    />
                    <p className={classNames({
                        'alert-input': 1,
                        'hidden': !this.state.alertEmail, 
                    })}>{this.state.alertEmail}</p>
                    <label>New password</label>
                    <input
                        type="password"
                        name="password"
                        onChange={this.handleChangeField}
                        value={form.password}
                    />
                    <p className={classNames({
                        'alert-input': 1,
                        'hidden': !this.state.alertPassword, 
                    })}>{this.state.alertPassword}</p>
                    <div className="captcha" id="grecaptcha-block"></div>
                    <div className="submit-block">
                        <input
                            type="submit"
                            className = {classNames({
                                'btn': 1,
                                'btn-reset': 1,
                                'hidden': this.state.hideSubmitBtn, 
                            })}
                            value="reset"
                        />
                        <img
                            alt ="preloader"
                            src={preloader}
                            className = {classNames({
                                preloader: 1,
                                hidden: this.state.hidePreloader,
                            })}
                        />
                    </div>
                </form>
                <div
                    className = {classNames({
                        notification: 1,
                        hidden: !this.state.hideForm,
                    })}>
                          <p>{this.state.notification}</p>
                          <p><a href={URL_FRONT}>product.prover.io</a></p>
                </div>
            </div>
        );
    }
}

export default ResetPassword;